cityNames = { '1': 'Boston', '2': 'NYC', '7': 'Chicago', '8': 'Philadelphia' ,'13': 'Atlanta', '17': 'Dallas' };
$(document).on('turbolinks:load', function() {

  if($('#page_type').val() == 'home') {
    homePageVisitedAmplitudeEvent();
  } else if ($('#page_type').val() == 'search') {
    searchPageVisitedAmplitudeEvent();
  }

  $('body').on('click', '.js-amplitude-restaurant-card', function() {
    var requestBody              = { page: 'search' };
    requestBody['city']          = cityNames[$('#city-drop-down').data('id')];
    requestBody['offering']      = getPageSelectedOffering();
    requestBody['source']        = 'list';
    requestBody['business_id']   = $(this).data('restaurantId');
    requestBody['business_name'] = $(this).data('restaurantName');
    amplitudeEventRequest('restaurant_detail_view', requestBody);
  });

  $('.js-amplitude-restaurant-card').keypress(function(event){
    if(event.which == 13){
      if ($(event.target).hasClass('goto-rdp')) {
        $(event.target).click();
      } else {
        $(this).click();
      }
    }
  });
  $('body').on('click', '.js-amplitude-reservation-intent', function() {
    var requestBody              = { page: 'search' };
    requestBody['city']          = cityNames[$('#city-drop-down').data('id')];
    requestBody['source']        = $(this).data('source');
    requestBody['business_id']   = $(this).data('restaurantId');
    requestBody['business_name'] = $(this).data('restaurantName');
    amplitudeEventRequest('reservation_intent', requestBody);
  });

  $('body').on('click', '.js-amplitude-order-intent', function() {
    var requestBody              = { page: 'search' };
    requestBody['city']          = cityNames[$('#city-drop-down').data('id')];
    requestBody['source']        = $(this).data('source');
    requestBody['business_id']   = $(this).data('restaurantId');
    requestBody['business_name'] = $(this).data('restaurantName');
    requestBody['order_method']  = $(this).data('orderMethod');
    amplitudeEventRequest('order_intent', requestBody);
  });

  $('body').on('click', '.js-amplitude-download-modal-btn', function(){
    var requestBody         = {};
    requestBody['page']     = $(this).data('page');
    requestBody['source']   = $(this).data('source');
    requestBody['offering'] = getPageSelectedOffering();
    $('#downloadModal').find('.send-text-btn').data('source', $(this).data('source'));
    amplitudeEventRequest('download_modal_view', requestBody);
  });

  $('body').on('click', '.js-see-all-click-amplitude-event', function(){
    var requestBody         = {};
    requestBody['offering'] = getPageSelectedOffering();
    amplitudeEventRequest('see_all_click', requestBody);
  });

  $('body').on('click', '.js-filter-apply-amplitude-event', function(){
    var requestBody                = {};
    requestBody['offering']        = getPageSelectedOffering();
    requestBody['source']          = $(this).data('source');
    requestBody['filter_category'] = $(this).data('filterCategory');
    requestBody['filter_name']     = $(this).data('filterName');
    amplitudeEventRequest('filter_apply', requestBody);
  });

  $('body').on('click', '.js-sort-apply-amplitude-event', function(){
    var requestBody         = {};
    requestBody['type']     = $(this).data('type');
    requestBody['offering'] = getPageSelectedOffering();
    amplitudeEventRequest('sort_apply', requestBody);
  });

  $('body').on('click', '.js-search-from-home-amplitude-event', function() {
    var requestBody            = {};
    requestBody['city']        = cityNames[$('#city-drop-down').data('id')];
    requestBody['offering']    = getPageSelectedOffering();
    requestBody['source']      = 'recommendations';
    requestBody['filter_name'] = $(this).data('filterName');
    requestBody['filter_type'] = $(this).data('filterCategory');
    amplitudeEventRequest('search_from_home', requestBody);
  });

  function homePageVisitedAmplitudeEvent() {
    var requestBody = { page: 'home' };
    amplitudeEventRequest('page_view', requestBody);
  }

  function searchPageVisitedAmplitudeEvent() {
    var requestBody = { page: 'search' };
    amplitudeEventRequest('page_view', requestBody);
  }

  function getPageSelectedOffering() {
    var offering = '';
    if ($('#page_type').val() == 'search') {
      offering = $('#search_page_booking_types').find('.js-offering-type:checked').val();
    } else if ($('#page_type').val() == 'home') {
      offering = $('#home_page_booking_types').find('.js-offering-type:checked').val();
    }
    return offering;
  }
});

window.amplitudeEventRequest = function(eventName, requestBody) {
  // var userId = $('#sessionId').text();
  // var params = { amplitude_event: { eventName: eventName, userId: userId, requestBody: requestBody } };
  // $.ajax({
  //   type: 'POST',
  //   url: '/amplitude_events',
  //   data: params
  // });
}

window.amplitudeRestaurantCardClickedEvent = function(marker) {
  var requestBody              = { page: 'search' };
  requestBody['city']          = cityNames[$('#city-drop-down').data('id')];
  requestBody['offering']      = $('#search_page_booking_types').find('.js-offering-type:checked').val();
  requestBody['source']        = 'map';
  requestBody['business_id']   = marker.id;
  requestBody['business_name'] = marker.title;
  amplitudeEventRequest('restaurant_detail_view', requestBody);
}

window.downloadModalPhoneEnterAmplitudeEvent = function(element) {
  var requestBody           = {}
  requestBody['page']       = $('#page_type').val();
  requestBody['source']     = element.data('source');
  if ($('#page_type').val() == 'search') {
    requestBody['offering'] = $('#search_page_booking_types').find('.js-offering-type:checked').val();
  } else if ($('#page_type').val() == 'home') {
    requestBody['offering'] = $('#home_page_booking_types').find('.js-offering-type:checked').val();
  }
  amplitudeEventRequest('download_modal_phone_enter', requestBody);
}

window.amplitudeOfferingToggleEvent = function(page, oldOffering, newOffering) {
  var requestBody              = {}
  requestBody['page']          = page;
  requestBody['from_offering'] = oldOffering;
  requestBody['to_offering']   = newOffering;
  requestBody['city']          = cityNames[$('#city-drop-down').data('id')];
  amplitudeEventRequest('offering_toggle', requestBody);
}

window.amplitudeCityToggleEvent = function(oldCity, newCity) {
  var requestBody          = {}
  requestBody['page']      = $('#page_type').val();
  requestBody['from_city'] = oldCity;
  requestBody['to_city']   = newCity;
  amplitudeEventRequest('city_toggle', requestBody);
}

window.amplitudeListMapToggleEvent = function(oldViewOption, newViewOption) {
  var requestBody            = {}
  requestBody['from_toggle'] = oldViewOption;
  requestBody['to_toggle']   = newViewOption;
  amplitudeEventRequest('list_map_toggle', requestBody);
}

window.amplitudeAddressEnterEvent = function(page, city, type, offering) {
  var requestBody         = {}
  requestBody['page']     = page;
  requestBody['city']     = cityNames[city];
  requestBody['type']     = type;
  requestBody['offering'] = offering;
  amplitudeEventRequest('address_enter', requestBody);
}

window.amplitudeSearchFromHomeEvent = function(cityId, offering, source, searchText, time_slot, maxSeat) {
  var requestBody            = {};
  requestBody['city']        = cityNames[cityId];
  requestBody['offering']    = offering;
  requestBody['source']      = source;
  requestBody['search_text'] = searchText;
  requestBody['slotForDate'] = time_slot;
  requestBody['maxSeat']     = maxSeat;
  amplitudeEventRequest('search_from_home', requestBody);
}
