$(document).on('turbolinks:load', function () {
  if (typeof Cookies !== 'undefined') {
    var code = $('#code').text();
    if (Cookies.get('code') == code && location.href.search('thanks') == -1 && location.href.search('relief') != -1) {
      // redirect to thankyou page
      var restaurant = Cookies.get('restaurant');
      var thanksLocation = location.href;
      thanksLocation = thanksLocation.split( '?' ).map( ( url, i ) => !i ? url : url.split( '&' ).filter( p => !p.startsWith( 'code=' ) ).join( '&' ) ).join( '?' )
      thanksLocation = thanksLocation.replace('relief', 'relief-thanks');
      thanksLocation += '&restaurant='+restaurant;
      location.href = thanksLocation;
    } else {
      Cookies.set('restaurant', '');
    }
    $('.relief-search-bar input').click(function(e) {
      $('.relief-search-bar').addClass('un-rounded-bottom');
      $('.relief-search-box').removeClass('d-none').addClass('un-rounded-top d-flex');
      e.stopPropagation();
    });

    $('.relief-search-box').click(function(e) {
      e.stopPropagation();
    });

    handleCheckboxClick();

    $(document).click(function() {
      closeReliefDropdown();
    });

    $('.relief-search-bar input').keyup(function() {
      var query = $(this).val();
      if (query == "") {
        $('.relief-search-box .initial-results').removeClass('d-none');
        $('.relief-search-box .suggested-results').addClass('d-none');
      } else {
        var city = $('#city').data().city;
        axios.get('relief/search?query='+query+'&city='+city).then(response => {
          $('.relief-search-box .initial-results').addClass('d-none');
          $('.relief-search-box .suggested-results').removeClass('d-none').html(response.data.data);
          handleCheckboxClick();
        }).catch(error => {
          console.log(error);
        })
      }
    });
  }
});

function closeReliefDropdown() {
  $('.relief-search-bar').removeClass('un-rounded-bottom');
  $('.relief-search-box').addClass('d-none').removeClass('un-rounded-top d-flex');
}

function handleCheckboxClick() {
  $('.relief-search-box li').click(function(e) {
    var this_checkbox = $(this).find('input:checkbox');
    // uncheck all checkboxes except this one
    $('input:checkbox').not(this_checkbox).prop('checked', false);
    // empty form restaurant value
    $('#relief-form input[name=company]').val("");
    if (this_checkbox.is(':checked')) {
      this_checkbox.prop('checked', false);
    } else {
      this_checkbox.prop('checked', true);
    }
    // proceed if checked
    if (this_checkbox.is(':checked')) {
      // get checked restaurant
      var restaurant = this_checkbox.attr('id').split('_')[0];
      // assign form restaurant value
      $('#relief-form input[name=company]').val(restaurant);
      // set restaurant cookie
      Cookies.set('restaurant',restaurant);
      if (restaurant == 'general') {
        $('.relief-search-bar input').val('General Fund');
      } else {
        $('.relief-search-bar input').val(restaurant);
      }
      $('#contact_restaurant_error').addClass('d-none');
      closeReliefDropdown();
    } else {
      $('.relief-search-bar input').val('');
      $('#contact_restaurant_error').removeClass('d-none');
      // Cookies.set('restaurant',"general");
    }
    e.stopPropagation();
  });
}