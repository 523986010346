$(document).on('turbolinks:load', function() {
  var sessionId = $('#sessionId').text();
  
  Adjust.initSdk({
    appToken: 'mxsevbu48o3k',
    environment: 'production',
    osName: 'web',
    deviceIds: {
      gpsAdid: sessionId
    },
    logLevel: 'verbose'
  });
});