$(document).on('turbolinks:load', function() {
  $(document).bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function() {
    var isFullScreen = document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen;
    if (isFullScreen) {
      console.log('fullscreen mode!');
      //Event Name - map_view
      mapFullView('map_view', $('#search-form input[name=current_location_latitude]').val(), $('#search-form input[name=current_location_longitude]').val(), $('#city-drop-down').val(), getGlobalData('markers').length, [map.center.lat(), map.center.lng()], $('#page_type').val(), 'web');
    }
  });
});

window.addMarkersListner = function() {
  var markers = getGlobalData('markers');
  markers.forEach(function(marker) {
    marker.addListener('click', function() {
      window.handleMarkerClick(marker);
    });
  });
}

window.selectMarker = function(marker) {
  if (selectedMarker) {
    var icon = getMarkerIcon(selectedMarker, false);
    selectedMarker.setIcon(icon);
    var selectedLabel = selectedMarker.getLabel();
    if (selectedLabel != '') {
      selectedLabel.color="#3A363D";
      selectedLabel.fontSize="11px";
    }
    selectedMarker.setLabel(selectedLabel);
  }
  var icon = getMarkerIcon(marker, true);
  marker.setIcon(icon);
  var label = marker.getLabel();
  if (label != '') {
    label.color="#fffdf9";
    label.fontSize="14px";
  }
  marker.setLabel(label);
  marker.setZIndex(highestZIndex+1);
  selectedMarker = marker;
};

window.centerChanged = function(){
  $('.restaurant-list-spinner').removeClass('d-none');
  var business_params       = searchRequestParams();
  business_params.page      = currentPage = 0;
  business_params.longitude = map.center.lng();
  business_params.latitude  = map.center.lat();
  business_params.sort      = 'distance';
  business_params.time_slot = business_params.slotForDate;

  $.ajax({
    type: 'GET',
    url: '/search/map_center_changed.js',
    data: business_params
  });
};

window.getMarkerIcon = function(marker, selected) {
  var icon = '';
  if (marker.restaurantAvailable){
    if (selected) {
      icon = marker.surging ? selectedIconSurging : selectedIcon;
    } else {
      icon = marker.surging ? normalIconSurging : normalIcon;
    }
  } else {
    icon   = selected ? selectedUnavailableIcon : unavailableIcon;
  }
  return icon;
}

// we will remove this code later on
// {
window.getMarkerIconOnRestaurant = function(restaurant, offering, searchedLocation, selected) {
  var isAvailable = isRestaurantAvailable(restaurant, offering, searchedLocation);
  var isSurging   = isResturantSurging(restaurant, offering);
  var icon = '';
  if(isAvailable) {
    if (isSurging) {
      icon = selected ? selectedIconSurging : normalIconSurging;
    } else {
      icon = selected ? selectedIcon : normalIcon;
    }
  } else {
    icon = selected ? selectedUnavailableIcon : unavailableIcon;
  }
  return icon;
}

window.isRestaurantAvailable = function(restaurant, offering, searchedLocation) {
  var isAvailable = false;
  if (offering   == 'dine_in') {
    isAvailable   = restaurant.isDineIn;
  } else if (offering == 'pickup') {
    isAvailable   = true;
  } else if (offering == 'delivery') {
    if (searchedLocation != 'ENTER YOUR ADDRESS') {
      if (restaurant.isOpen) {
        isAvailable = restaurant.isDeliveryInRange;
      } else {
        isAvailable = false;
      }
    } else {
      isAvailable = true;
    }
  }
  return isAvailable;
}

function isResturantSurging(restaurant, offering) {
  var isSurging = false;
  if (offering == 'dine_in') {
    isSurging   = restaurant.isSurging;
  } else if (offering == 'pickup') {
    isSurging   = restaurant.isPickupSurging;
  } else if (offering == 'delivery') {
    isSurging   = restaurant.isDeliverySurging;
  }
  return isSurging;
}
// }

