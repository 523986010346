// GOOGLE ANALYTICS 4 CUSTOM EVENTS

$(document).on('turbolinks:load', function() {
  // Event Name - download_app_modal_clicked
  downloadAppModalClicked('download_app_modal_clicked', '.kba-nav-upload-btn', "Home Page - Main Nav", "upload_receipt", null);
  downloadAppModalClicked('download_app_modal_clicked', '.btn-download-app', "Home Page", "download_app", null);
  downloadAppModalClicked('download_app_modal_clicked', '.place-order-item-link', "Home Page - Main Nav", "place_order", null);
  downloadAppModalClicked('download_app_modal_clicked', '#rdp-order-btn .uploadRecipt', "Search Page - RDP", "upload_receipt", null);
  downloadAppModalClicked('download_app_modal_clicked', '.book-now-cta-btn .kba-time-slots', "Search Page - RDP", "download_app", null);
  downloadAppModalClicked('download_app_modal_clicked', '.book-now-cta-btn .kba-time-slots-display', "Search Page - RDP", "download_app", null);
  // Event Name - download_app_modal_viewed
  downloadAppModalClicked('download_app_modal_viewed', '.kba-nav-upload-btn', "Home Page - Main Nav", "upload_receipt", null);
  downloadAppModalClicked('download_app_modal_viewed', '.btn-download-app', "Home Page", "download_app", null);
  downloadAppModalClicked('download_app_modal_viewed', '#rdp-order-btn .uploadRecipt', "Search Page - RDP", "upload_receipt", null);
  downloadAppModalClicked('download_app_modal_viewed', '.book-now-cta-btn .kba-time-slots', "Search Page - RDP", "download_app", null);
  downloadAppModalClicked('download_app_modal_viewed', '.book-now-cta-btn .kba-time-slots-display', "Search Page - RDP", "download_app", null);
  // Event Name - book_event_button_clicked
  bookAnEventTracking('book_event_button_clicked', 'event_planning_page');
  // Event Name - join_seated_clicked
  joinSeatedClickedTracking('join_seated_clicked', 'for_restaurants_page');
  // Event Name - reservation_intent
  dineInReservationClicked('reservation_intent', $("#page_type").val(), 'web');
  // Event Name - place_an_order
  placeOrderClicked('place_an_order', $("#page_type").val(), 'web');

  // Event Name - download app cta
  downloadAppCTA('download_app_cta', '.download-app-cta', 'Home Page - Sticky Nav', null, null)
  downloadAppCTA('download_app_cta', '.download-app-cta-upload-receipt-modal', 'Home Page - Main Nav', 'upload_receipt', null)
  downloadAppCTA('download_app_cta', '.sidebar-download-app-cta', 'Sidebar - Footer', null, null)
});

function downloadAppModalClicked(eventName, Klass, source, modalType, modality) {
  $('body').on('click', Klass, function(event) {
    console.log(`----- ${eventName} -----`);
    var businessId = "";
    var businessName ="";
    if(jQuery.inArray(Klass,
      [ '#rdp-order-btn .uploadRecipt',
        '.book-now-cta-btn .kba-time-slots',
        '.book-now-cta-btn .kba-time-slots-display']) !== -1) {
      businessId = $("#restaurant_id").val();
      businessName = $("#restaurant_name").text();
    }

    gtag("event", eventName, {
      source: source,
      modal_type: modalType,
      business_id: businessId,
      business_name: businessName,
      modality: modality
    });
  });
}

function downloadAppCTA(eventName, Klass, source, modalType, modality) {
  $('body').on('click', Klass, function(event) {
    console.log(`----- ${eventName} -----`);
    var businessId = "";
    var businessName ="";
    if(jQuery.inArray(Klass,
      [ '.download-app-cta',
        '.download-app-cta-upload-receipt-modal',
        '.sidebar-download-app-cta']) !== -1) {
      businessId = $("#restaurant_id").val();
      businessName = $("#restaurant_name").text();
    }
    gtag("event", eventName, {
      source: source,
      modal_type: modalType,
      business_id: businessId,
      business_name: businessName,
      modality: modality
    });
  });
}

function searchBarClickTracking(eventName, source, market, platform, modality) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page'
    gtag("event", eventName, {
    source: source_page,
    market: market,
    platform: platform,
    modality: modality
  });
}

function searchQueryResponseTracking(eventName, source, search_key, market, restaurant_count, platform) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page'
  gtag("event", eventName, {
    source: source_page,
    search_key: search_key,
    market: market,
    restaurant_count: restaurant_count,
    platform: platform
  });
}

function cityChangedEvent(eventName, from_city, to_city, source, platform) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page'
  gtag("event", eventName, {
    from_city: from_city,
    to_city: to_city,
    source: source_page,
    platform: platform
  });
}

function filterCategoryChanged(eventName, from_category, to_category, source, platform) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page'
  gtag("event", eventName, {
    from_category: from_category,
    to_category: to_category,
    source: source_page,
    platform: platform
  });
}

function popularSearchSelected(eventName, search_key, search_position, market, modality, source, platform, restaurant_count) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page';

  gtag("event", eventName, {
    source: source_page,
    search_key: search_key,
    search_position: search_position,
    market: market,
    platform: platform,
    modality: modality,
    restaurant_count: restaurant_count
  });
}

function filterSelectedRemovedEvent(eventName, source, platform, restaurant_count) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page';
  var offering = '';
  if (source == 'search') {
    offering = $('#search_page_booking_types').find('.js-offering-type:checked').val();
  } else if (source == 'home') {
    offering = $('#home_page_booking_types').find('.js-offering-type:checked').val();
  }

  var filters = $.makeArray($('.filters-desktop input:checkbox:checked'));
  filters = $.map(filters, function(box) {return box.id.replace('xand', '&');});

  var price = $('.price-bubble.selected-price').first().text();
  var payload = {
    source: source_page,
    platform: platform,
    restaurant_count: restaurant_count,
    booking_selected: offering
  }

  if(price && price != '') { payload['price_selected'] = price }
  filters.map(function(item) {
    if(item.includes('neighborhoods')) {
      payload['neighborhood_selected'] = item.split('-')[0]
    }
    else if(item.includes('cuisines')) {
      payload['cuisines_selected'] = item.split('-')[0]
    }

    else if(item.includes('mood')) {
      payload['mood_selected'] = item.split('-')[0]
    }
  })

  gtag("event", eventName, payload);
}

function phoneNoEnteredTracking(modalType, source, errorMessage) {
  var businessId = $("#restaurant_id").val();
  var businessName = $("#restaurant_name").text();
  console.log('----- phone_no_entered -----');
  gtag("event", 'phone_no_entered', {
    source: source,
    modal_type: modalType,
    business_id: businessId,
    business_name: businessName,
    error_message: errorMessage
  });
}

function phoneNoEnteredErrorTracking(errMessage) {
  if(window.location.pathname == "/search") {
    phoneNoEnteredTracking("time_slot", "search_page", errMessage);
  }

  if(window.location.pathname == "/") {
    phoneNoEnteredTracking("download_app", "homepage", errMessage);
  }

  if(window.location.pathname == "/text-to-download") {
    phoneNoEnteredTracking("download_app", "text_to_download_page", errMessage);
  }
}

function bookAnEventTracking(eventName, source) {
  $('body').on('click', '.book-event-btn', function(event) {
    console.log(`----- ${eventName} -----`);
    gtag("event", eventName, {
      source: source
    });
  });
}

function joinSeatedClickedTracking(eventName, source) {
  $('body').on('click', '.join-seated-btn', function(event) {
    console.log(`----- ${eventName} -----`);
    gtag("event", eventName, {
      source: source
    });
  });
}

function eventFormActionsTracking(eventName) {
  console.log(`----- ${eventName} -----`);
  gtag("event", eventName, {});
}

function joinSeatedSalesTracking(eventName) {
  console.log(`----- ${eventName} -----`);
  gtag("event", eventName, {});
}

function mapFullView(eventName, user_latitude, user_longitude, selected_city, number_of_pins, map_center, source, platform) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page';

  gtag("event", eventName, {
    source: source_page,
    user_latitude: user_latitude,
    user_longitude: user_longitude,
    selected_city: selected_city,
    number_of_pins: number_of_pins,
    map_center: map_center,
    platform: platform
  });
}

function mobileListViewClicked(eventName, source, platform) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page';

  gtag("event", eventName, {
    source: source_page,
    platform: platform
  });
}

function rdpViewOpened(eventName, source, offering, business_id, business_name, business_city, has_availability, url, platform, is_surging) {
  console.log(`----- ${eventName} -----`);
  var source_page = source == 'home' ? 'homepage' : 'search_page';

  gtag("event", eventName, {
    source: source_page,
    offering: offering,
    business_id: business_id,
    business_name: business_name,
    business_city: business_city,
    has_availability: has_availability,
    URL: url,
    platform: platform,
    is_surging: is_surging
  });
}

function productBarClicked(eventName, business_id, business_name, source, platform, from_modality, to_modality) {
  console.log(`----- ${eventName} -----`);
  var source_page;
  if(source == 'home') {
    source_page = 'homepage';
  }
  else if(source == 'search') {
    source_page = 'search_page';
  } else {
    source_page = source;
  }

  gtag("event", eventName, {
    business_id: business_id,
    business_name: business_name,
    source: source_page,
    platform: platform,
    from_modality: from_modality,
    to_modality: to_modality
  });
}

function dineInReservationClicked(eventName, source, platform) {
  $('body').on('click', '.js-time-slot, .walk-in-btn', function(event) {
    console.log(`----- ${eventName} -----`);
    var id, name, reservation_type;
    if($(this).parents('.restaurant-card-map').length) {
      id = $(this).parents('.restaurant-card-map').data('id');
      name = $(this).parents('.restaurant-card-map').data('restaurant-name');

    } else {
      id = $('#restaurant_id').val();
      name = $('#restaurant_name').text();
    }

    reservation_type = $(this).hasClass('walkIn-cta') || $(this).hasClass('walk-in-btn') ? 'walk_in' : 'reservation';

    var source_page = source == 'home' ? 'homepage' : 'search_page';

    gtag("event", eventName, {
      source: source_page,
      business_id: id,
      business_name: name,
      business_city: $('#city-drop-down').val(),
      reservation_type: reservation_type,
      platform: platform
    });
  });
}

function placeOrderClicked(eventName, source, platform) {
  $('body').on('click', '.js-amplitude-order-intent', function () {
    console.log(`----- ${eventName} -----`);

    var source_page = source == 'home' ? 'homepage' : 'search_page';

    gtag("event", eventName, {
      source: source_page,
      order_type: $(this).data('orderMethod'),
      platform: platform
    });
  });
}

function externalRdpLinkClicked(eventName, source, platform, type) {
  console.log(`----- ${eventName} -----`);

  var source_page = source == 'home' ? 'homepage' : 'search_page';

  gtag("event", eventName, {
    source: source_page,
    business_id: $('#restaurant_id').val(),
    business_name: $('#restaurant_name').text(),
    business_city: $('#city-drop-down').val(),
    link_clicked: type,
    platform: platform
  });
}

function mapPinClicked(eventName, source, platform, marker, user_latitude, user_longitude) {
  console.log(`----- ${eventName} -----`);

  var source_page = source == 'home' ? 'homepage' : 'search_page';

  gtag("event", eventName, {
    source: source_page,
    business_id: marker.id,
    isDineInSurging: marker.restaurant.isSurging || false,
    IsDeliverySurging: marker.restaurant.IsDeliverySurging || false,
    IsPickupSurging: marker.restaurant.IsPickupSurging || false,
    user_latitude: user_latitude,
    user_longitude: user_longitude,
    reward_percentage_dine_in: marker.restaurant.maxReward || marker.restaurant.baseReward,
    reward_percentage_delivery: marker.restaurant.deliveryReward || 0,
    reward_percentage_pickup: marker.restaurant.pickupReward || 0,
    position_in_results: marker.list_position,
    platform: platform
  });
}

function timeSlotReservationClicked(eventName, source, platform, reward, business, city) {
  console.log(`----- ${eventName} -----`);

  gtag("event", eventName, {
    source: source,
    platform: platform,
    reward_percentage_dine_in: reward,
    business_id: business,
    business_city: city
  });
}

function cityMapPinTap(eventName, source, platform, reward, businessId, businessName, businessCity) {
  console.log(`----- ${eventName} -----`);
  gtag("event", eventName, {
    source: source,
    platform: platform,
    reward_percentage_dine_in: reward,
    business_id: businessId,
    business_name: businessName,
    business_city: businessCity
  });
}

function downloadAppCTACityPage(eventName, source, sourceDetail, modalType) {
  console.log(`----- ${eventName} -----`);

  gtag("event", eventName, {
    source: source,
    source_detail: sourceDetail,
    modal_type: modalType
  });
}

function cityChangedEventCityPage(eventName, source, sourceDetail, platform, fromCity, toCity) {
  console.log(`----- ${eventName} -----`);

  gtag("event", eventName, {
    source: source,
    source_detail: sourceDetail,
    platform: platform,
    from_city: fromCity,
    to_city: toCity
  });
}


function cityPageVideoModalOpened() {
  console.log('----- how_it_works_video_opened -----');

  gtag("event", 'how_it_works_video_opened', {});
}

function cityPageVideoModalClosed(eventName, duration) {
  console.log(`----- ${eventName} -----`);

  gtag("event", eventName, {
    duration: duration
  });
}

function cityPagePickerChanged(eventName, fromDate, toDate, fromPartySize, toPartySize, fromTime, toTime) {
  console.log(`----- ${eventName} -----`);

  gtag("event", eventName, {
    from_date: fromDate,
    to_date: toDate,
    from_party_size: fromPartySize,
    to_party_size: toPartySize,
    from_time: fromTime,
    to_time: toTime
  });
}

function cityPageRestaurantCardClicked(eventName, source, sourceDetail, platform) {
  console.log(`----- ${eventName} -----`);

  gtag("event", eventName, {
    source: source,
    source_detail: sourceDetail,
    platform: platform
  });
}

function cityPageLoad(availableRestaurants, unavailableRestaurants) {
  gtag("event", "page_load", {
    count_restaurants_with_availability: availableRestaurants,
    count_restaurants_no_availability: unavailableRestaurants
  });
}

window.phoneNoEnteredTracking = phoneNoEnteredTracking;
window.joinSeatedSalesTracking = joinSeatedSalesTracking;
window.eventFormActionsTracking = eventFormActionsTracking;
window.phoneNoEnteredErrorTracking = phoneNoEnteredErrorTracking;
window.searchQueryResponseTracking = searchQueryResponseTracking;
window.cityChangedEvent = cityChangedEvent;
window.popularSearchSelected = popularSearchSelected;
window.filterSelectedRemovedEvent = filterSelectedRemovedEvent;
window.filterCategoryChanged = filterCategoryChanged;
window.searchBarClickTracking = searchBarClickTracking;
window.mapFullView = mapFullView;
window.mobileListViewClicked = mobileListViewClicked;
window.rdpViewOpened = rdpViewOpened;
window.productBarClicked = productBarClicked;
window.externalRdpLinkClicked = externalRdpLinkClicked;
window.mapPinClicked = mapPinClicked;
window.timeSlotReservationClicked = timeSlotReservationClicked;
window.cityMapPinTap = cityMapPinTap;
window.downloadAppCTACityPage = downloadAppCTACityPage;
window.cityChangedEventCityPage = cityChangedEventCityPage;
window.cityPageVideoModalOpened = cityPageVideoModalOpened;
window.cityPageVideoModalClosed = cityPageVideoModalClosed;
window.cityPagePickerChanged = cityPagePickerChanged;
window.cityPageRestaurantCardClicked = cityPageRestaurantCardClicked;
window.cityPageLoad = cityPageLoad;