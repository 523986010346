$(document).on('turbolinks:load', function() {
    setFooterTheme();
    var availableRestaurantsCount = $('.cityPage').data('availableRestaurantsCount');
    var unavailableRestaurantsCount = $('.cityPage').data('unavailableRestaurantsCount');
    cityPageLoad(availableRestaurantsCount, unavailableRestaurantsCount);
    var cityId = $('.cityPage').data('cityId');
    $(`.cityListOption-${cityId}`).addClass('active-element');

    var resNameArr = $('.city__RestaurantList__card__heading');
    document.addEventListener('scroll', function (event) {
        resNameArr.map((i,e) => {
            if (isInViewport(e)){
                if (!mapLoaded) {
                    return;
                }
                var latitude = parseFloat(e.dataset.latitude);
                var longitude = parseFloat(e.dataset.longitude);
                if (latitude && longitude) {
                    map.setZoom(13);
                    for (var marker of markers) {
                        if (marker.position.lat() == latitude && marker.position.lng() == longitude) {
                            highestZIndex++;
                            window.selectMarker(marker);
                            map.panTo(marker.getPosition());
                            infowindow = getGlobalData('infowindow');
                            infowindow.close();
                            break;
                        }
                    }
                }
            }
        });
    }, true);

    $(".cityPage").scroll(function(){
        var sticky = $('.city__map__wrapper'),
            scroll = $(this).scrollTop();
        if (scroll >= ($(".city__banner__left").height() + 100)) {
            sticky.addClass('sticky-map');
            $('.city__mobileMap__header').addClass("stickymapheader");
        }
        else {
            sticky.removeClass('sticky-map');
            $('.city__mobileMap__header').removeClass("stickymapheader");
        }
    });
    const select = document.querySelectorAll('.selectBtn');
    const option = document.querySelectorAll('.customOption');
    let index = 1;

    select.forEach(a => {
        a.addEventListener('click', b => {
            if($(b.target).hasClass('selectBtn')) {
                const next = b.target.nextElementSibling;
                next.classList.toggle('toggle');
                next.style.zIndex = index++;
            }
        })
    })
    option.forEach(a => {
        a.addEventListener('click', b => {
            b.target.parentElement.classList.remove('toggle');

            const parent = b.target.closest('.customSelect').children[0];
            parent.setAttribute('data-type', b.target.getAttribute('data-type'));
            parent.innerText = b.target.innerText;
        })
    });

    $("body").on("click", ".selectBtn__text", function() {
        $(".customSelect .selectBtn").trigger('click');
    });

    if($('#page_type').length && ["nyc", "dallas", "boston", "atlanta"].includes($('#page_type').val()) && $('.city__header__timeSlotWrapper').length > 0) {
        $(".cityListJs[data-id="+$('#home-city-id').data().id+"]").addClass('active-element');
    }

    $("body").on("click", ".cityPage .NYCContentContainer__ul .cityListJs", function(e) {
        var oldCity = $('.cityPage').data('cityId');
        $('.cityPage').data('cityId', $(this).data().id);
        var newCity = $('.cityPage').data('cityId');
        cityChangedEventCityPage('city_change', `city_page_${get_city_name(oldCity)}`, 'top_nav', 'web', `${get_city_name(oldCity)}`, `${get_city_name(newCity)}`);
        getCityPageDataRequest();
    });

    $("body").on("click", ".cityPage .GuestContentContainer__ul .guestListJs", function(e) {
        var fromPartySize = $('#maxSeatGuest').text();
        var toPartySize = `${$(this).data().id}`;
        var fromDateTime = $('#time_picker').val();
        var toDateTime = fromDateTime;
        triggerPickerChangeEvent(fromDateTime, toDateTime, fromPartySize, toPartySize);
      $('#maxSeatGuest').text($(this).data().id);
        getCityPageDataRequest();
    });

    $("body").on("click", ".cityPage .TimeContentContainer__ul .timeSlotJs", function(e) {
        var fromPartySize = $('#maxSeatGuest').text();
        var toPartySize = fromPartySize;
        var fromDateTime = $('#time_picker').val();
        var toDateTime = [$('#time_picker').val().trim().split(' ')[0], $(this).text().trim()].join(' ');
        triggerPickerChangeEvent(fromDateTime, toDateTime, fromPartySize, toPartySize);
        $('#time_picker').val([$('#time_picker').val().trim().split(' ')[0], $(this).text().trim()].join(' '));
        getCityPageDataRequest();
    });

    $("body").on("click", ".cityPage .selectDropdown .bannerCityOption", function(e) {
        var oldCity = $('.cityPage').data('cityId');
        var newCity = $(this).data().id;
        cityChangedEventCityPage('city_change', `city_page_${get_city_name(oldCity)}`, 'hero', 'web', `${get_city_name(oldCity)}`, `${get_city_name(newCity)}`);
        var baseUrl = $('.cityPage').data('baseUrl');
        var requestUrl = getRequestURL($(this).data().id);
        window.location.href = `${baseUrl}/${requestUrl}`;
    });

    $('body').on('click', '.cityPage .cityTimeSlotJS', function (e) {
        var city = $('.cityPage').data('cityId');
        timeSlotReservationClicked('reservation_intent', `city_page_${get_city_name(city)}`, 'web', `${$(this).data().reward}`, `${$(this).data().restaurantId}`, `${get_city_name(city)}`)
        var selectedPartySize = '';
        var reservationDate = '';
        var selectedPartySizeString = '';
        var reservationDateString = '';

        $('#rdp-modal-banner-image').attr('src', $(this).data().imgUrl);
        $('#rdp-modal-restaurant-name').text($(this).data().restaurantName);
        $('#time_slot_reward').text(`${$(this).data().reward}%`);
        $('#selected-time-slot').text(this.children[1].innerText);
        selectedPartySize = $('#maxSeatGuest').text().trim();
        reservationDate = $('#show-date').text();
        selectedPartySizeString = formatPartySize(selectedPartySize);
        $('#selected-party-size').text(selectedPartySizeString);
        reservationDateString = formatReservationDate(reservationDate, reservationDateString);
        $('#selected-date-download-modal').text(reservationDateString);
        $('#downloadmodal').modal('show');
    });

    $("body").on("click", ".cityPage .goto-rdp", function(e) {
        var baseUrl = $('.cityPage').data('baseUrl');
        var city = $('.cityPage').data('cityId');
        var time_slot = $("#time_picker").val();
        var maxSeat = $("#maxSeatGuest").text();
        var restaurantId = $(this).data().restaurantId || $(this).data().restaurantid;
        var restaurantName = $(this).data().restaurantName || $(this).data().restaurantname;
        var latitude = $(this).data().latitude;
        var longitude = $(this).data().longitude;
        cityPageRestaurantCardClicked('restaurant_card_clicked', `city_page_${get_city_name(city)}`, `${getSourceDetail($(this))}`, 'web');
        window.location.href = `${baseUrl}/search?city=${city}&time_slot=${time_slot}&maxSeat=${maxSeat}&restaurantId=${restaurantId}&restaurantName=${restaurantName}&latitude=${latitude}&longitude=${longitude}&redirectFrom=cityPage`;
    });

    $("body").on("click", ".cityPage .goto-search", function(e) {
        e.preventDefault();
        var baseUrl = $('.cityPage').data('baseUrl');
        var city = $('.cityPage').data('cityId');
        var time_slot = $("#time_picker").val();
        var maxSeat = $("#maxSeatGuest").text();
        var filter = $(this).data().filter
        var latitude = $(this).data().latitude;
        var longitude = $(this).data().longitude;
        window.location.href = `${baseUrl}/search?city=${city}&time_slot=${time_slot}&maxSeat=${maxSeat}&filters=${filter}&latitude=${latitude}&longitude=${longitude}&offering=dine_in`;
    });

    $("#cityVideoModal").on('hidden.bs.modal', function (e) {
        $("#cityVideoModal video").trigger('pause');
        var duration = $("#cityVideoModal video")[0].currentTime
        duration = `${duration.toFixed(2)} seconds`;
        cityPageVideoModalClosed('how_it_works_video_closed', duration);
    });

    const city__carouselContainer = document.querySelector(".city__carouselContainer");
    // For Carousel Cities Restaurants
    city__carouselContainer && $('.city__carouselContainer__wrapper').not('.slick-initialized').slick({
        dots: true,
        rows: 2,
        slidesPerRow: 6,
        prevArrow: `<button class="slide-arrow prev-arrow"><span></span></button>`,
        nextArrow: `<button class="slide-arrow next-arrow"><span></span></button>`,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesPerRow: 2,
                    rows: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesPerRow: 2,
                    rows: 2
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesPerRow: 3,
                    rows: 2
                }
            }
        ]
    });

    $("body").on("click", ".cityPage .plan-en-event-btn", function(e) {
        cancelDropDown();
    });

    $("body").on("click", ".downloadmodal__inputContainer__buttonMobile", function(e) {
        if(["nyc", "dallas", "boston", "atlanta"].includes($('#page_type').val())) {
            var city = $('.cityPage').data('cityId');
            downloadAppCTACityPage('download_app_modal_clicked', `city_page_${get_city_name(city)}`, `${getCurrentDeviceOS()}`,'download_app');
            downloadAppCTACityPage('download_app_modal_viewed', `city_page_${get_city_name(city)}`, `${getCurrentDeviceOS()}`,'download_app');
        }
    });

    $("body").on("click", ".cityPage .city__banner__left__videoButton", function(e) {
        cityPageVideoModalOpened();
    });

    $('body').on('keypress','.cityPage .selectBtn', function(event){
        if(event.which == 13){
          $(this).click();
          var tabindex = parseInt($(this).attr('tabindex'));
          var options = $('.bannerCityOption');
          for(let i=0;i< options.length; i++){
            options[i].tabIndex = tabindex+1;
          }
        }
    });

    $('body').on('keypress','.cityPage .bannerCityOption', function(event){
        if(event.which == 13){
          $(this).click();
        }
    });

    $('.cityPage .selectBtn').focusin(function(){
        cancelDropDown();
    });

    $('.cityPage .city__banner__left__videoButton').focusin(function(){
        $('.selectDropdown').removeClass('toggle');
    });

    $('body').on('keypress','.close-modal', function(event){
        if(["nyc", "dallas", "boston", "atlanta"].includes($('#page_type').val())) {
            if(event.which == 13){
                $('#cityVideoModal').modal('hide');
                $(".modal-backdrop").remove();
              }
        }
    });

    $('body').on('keypress','.cityPage .goto-rdp', function(event){
        if(event.which == 13){
            $(this).click();
        }
    });

    if($('.city__RestaurantList__card').length > 0){
        var lastViewBtnElement = $('.city__RestaurantList__card')[$('.city__RestaurantList__card').length-1].querySelector('.city__viewMoreButton');
        var lastDotsBtnElement = $('.city__RestaurantList__card')[$('.city__RestaurantList__card').length-1].querySelector('.city__timeSlot__dots');
        if(lastViewBtnElement){
            $('.city__carousel__headingBox__viewAll').attr( 'tabIndex', (lastViewBtnElement.tabIndex+1) );
            $('.prev-arrow').attr( 'tabIndex', (lastViewBtnElement.tabIndex+2) );
            setTabIndexofCarouselCards(lastViewBtnElement);
            $('.next-arrow').attr( 'tabIndex', (lastViewBtnElement.tabIndex+4) );
            $('.app-store-link').attr( 'tabIndex', ($('.next-arrow')[0].tabIndex+1));
            setTabIndexofElements();
        } else if(lastDotsBtnElement){
            $('.city__carousel__headingBox__viewAll').attr( 'tabIndex', (lastDotsBtnElement.tabIndex+1) );
            $('.prev-arrow').attr( 'tabIndex', (lastDotsBtnElement.tabIndex+2) );
            setTabIndexofCarouselCards(lastDotsBtnElement);
            $('.next-arrow').attr( 'tabIndex', (lastDotsBtnElement.tabIndex+4));
            $('.app-store-link').attr( 'tabIndex', ($('.next-arrow')[0].tabIndex+1));
            setTabIndexofElements();
        }
    }

    $('.cityPage').on('beforeChange', function(event){
        var activeSlideCards = $('.slick-current').find('.city__carousel__card');
        activeSlideCards.map((index, element) => {
            element.removeAttribute("tabIndex");
        });
    });

    $('.cityPage').on('afterChange', function(event){
        var tabindex = $('.prev-arrow')[0].tabIndex;
        var activeSlideCards = $('.slick-current').find('.city__carousel__card');
        $('.slick-current').removeAttr("tabIndex");
        var firstActiveCard;
        activeSlideCards.map((index, element) => {
            if(index == 0) {
                firstActiveCard = element;
            }
            element.tabIndex = (tabindex+1);
        });
        $('.next-arrow').attr( 'tabIndex', (tabindex+2));
        $('.app-store-link').attr( 'tabIndex', (tabindex+3));
        setTabIndexofElements();
    });

    $('body').on('keypress','.cityPage .next-arrow,.cityPage .prev-arrow', function(event){
        focusFirstCarouselCard(event);
    });

    $('body').on('keydown','.city__carousel', function(event){
        focusFirstCarouselCard(event);
    });

    $("body").on("click", ".cityPage .cityTimeSlotJS", function(e) {
        var tabindex = this.tabIndex;
        $('.QRMOdal__Container__QRBOx').attr( 'tabIndex', tabindex);
        $('.kba-close').attr( 'tabIndex', tabindex);
    });

    $("body").on("keypress", ".cityPage .cityTimeSlotJS", function(e) {
        if(e.which == 13){
            var tabindex = this.tabIndex;
            $('.QRMOdal__Container__QRBOx').attr( 'tabIndex', tabindex);
            $('.kba-close').attr( 'tabIndex', tabindex);
          }
    });

    if(["nyc", "dallas", "boston", "atlanta"].includes($('#page_type').val())) {
        $('.QRMOdal__Container__QRBOx').focusout(function(){
            $('.kba-close').focus();
        });

        $("#cityVideoModal").on('shown.bs.modal', function (e) {
            $('.video-modal-close').focus();
        });

        $('.video-modal-close').focusout(function(){
            $('.video-player').focus();
        });
    }

    $('body').on('keypress','.cityPage .DateButtonContainer', function(event){
        if(event.which == 13){
          var tabindex = parseInt($(this).attr('tabindex'));
          $('.cityPage .rd-container .rd-date .rd-month .rd-back').attr( 'tabIndex', tabindex+1);
          $('.cityPage .rd-container .rd-date .rd-month .rd-next').attr( 'tabIndex', tabindex+1);

          var dates = $('.rd-day-body');
          dates.map((index, element) => {
            if(element.classList.length == 1) {
                element.tabIndex = tabindex+2;
            }
          })
        }
    });

    $('body').on('keypress','.cityPage .rd-container .rd-date .rd-month .rd-day-body', function(event){
        if(event.which == 13){
            $(this).click();
        }
    });

    $('body').on('keydown','.cityPage .footer', function(event){
        if(event.target.text.slice(2) == 'PRIVACY CHOICES' && event.key == 'Tab' && !event.shiftKey) {
            event.preventDefault();
            $('.city__header__logoWrapper').focus();
        }
    });

    $('body').on('keydown','.cityPage .city__header__logoWrapper', function(event){
        if($(this).hasClass("city__header__logoWrapper") && event.key == 'Tab' && event.shiftKey) {
            event.preventDefault();
            $('.privacy-choices-link-footer').focus();
        }
    });

});

window.getCityPageDataRequest = function() {
    var baseUrl = $('.cityPage').data('baseUrl');
    var city = $('.cityPage').data('cityId');
    var requestUrl = getRequestURL(city);
    var time_slot = $("#time_picker").val();
    var maxSeat = $("#maxSeatGuest").text();
    window.location.href = `${baseUrl}/${requestUrl}?city=${city}&slotForDate=${time_slot}&maxSeat=${maxSeat}`;
};

window.getRequestURL = function(city) {
    if(city == 2) {
        return "nyc";
    } else if(city == 17) {
        return "dallas";
    } else if(city == 1) {
        return "boston";
    } else if(city == 13) {
        return "atlanta";
    }
};

function cancelDropDown(){
    const dropDownBoxContainer = document.querySelectorAll(".dropDownBoxContainer");
    const dropDownContentContainer = document.querySelectorAll(".dropDownContentContainer");
    const backdrop__layout = document.querySelector(".backdrop__layout");
    dropDownBoxContainer.forEach(box => box.classList.remove("dropDownBoxContainer__click"));
    dropDownContentContainer.forEach(content => content.classList.remove("dropDownContentContainer__click"));
    backdrop__layout.classList.remove("backdrop__layout__active");
}

window.get_city_name = function(city) {
    if(city == 2) {
        return "NYC";
    } else if(city == 17) {
        return "Dallas";
    } else if(city == 1) {
        return "Boston";
    } else if(city == 13) {
        return "Atlanta";
    }
}

function getCurrentDeviceOS(){
    const userAgent = navigator.userAgent;
    if(/android/i.test(userAgent)){
        return 'android_cta';
    }
    if(/iPad|iPhone|iPod/i.test(userAgent)){
        return 'ios_cta';
    }
}

window.triggerPickerChangeEvent = function(fromDateTime, toDateTime, fromPartySize, toPartySize) {
    var fromDate = fromDateTime.substring(0,10);
    var toDate   = toDateTime.substring(0,10);
    var fromTime = fromDateTime.slice(11);
    var toTime   = toDateTime.slice(11);
    if(fromTime[0] == "0") {
        fromTime = fromTime.substring(1);
    }
    if(toTime[0] == "0") {
        toTime = toTime.substring(1);
    }
    cityPagePickerChanged('picker_changed', fromDate, toDate, fromPartySize, toPartySize, fromTime, toTime);
}

function getSourceDetail(element) {
    if(element.hasClass("popupWrapper")) {
        return "map";
    } else if(element.hasClass("city__carousel__card")) {
        return "carousel";
    } else if(element.hasClass("city__timeSlot__dots") || element.hasClass("city__RestaurantList__card__viewMoreContainer")) {
        return "view_more_times";
    } else if(element.hasClass("city__RestaurantList__card__imageWrapper__img") || element.hasClass("city__RestaurantList__card__heading") || element.hasClass("city__RestaurantList__card__desc")) {
        return "list";
    }
}


function setTabIndexofElements() {
    $('.play-store-link').attr( 'tabIndex', ($('.app-store-link')[0].tabIndex+1));
    var cuisineFilters = $('.cuisine-filters');
    cuisineFilters.map((index, element) => {
        element.tabIndex = ($('.play-store-link')[0].tabIndex+2);
    });
    var moodFilters = $('.mood-filters');
    moodFilters.map((index, element) => {
        element.tabIndex = ($('.play-store-link')[0].tabIndex+3);
    });
    $('.city__popularSearch__viewMore').attr( 'tabIndex', ($('.play-store-link')[0].tabIndex+4));
    var sidebarCityLinks = $('.city__linkBox');
    sidebarCityLinks.map((index, element) => {
        if(!$(element).hasClass("d-none")){
            element.tabIndex = ($('.city__popularSearch__viewMore')[0].tabIndex+1);
        }
    });
    $('.blog-link-footer').attr( 'tabIndex', ($('.city__popularSearch__viewMore')[0].tabIndex+2));
    $('.faq-link-footer').attr( 'tabIndex', ($('.blog-link-footer')[0].tabIndex+1));
    $('.careers-link-footer').attr( 'tabIndex', ($('.faq-link-footer')[0].tabIndex+1));
    $('.press-link-footer').attr( 'tabIndex', ($('.careers-link-footer')[0].tabIndex+1));
    $('.contact-link-footer').attr( 'tabIndex', ($('.press-link-footer')[0].tabIndex+1));
    $('.atlanta-link-footer').attr( 'tabIndex', ($('.contact-link-footer')[0].tabIndex+1));
    $('.boston-link-footer').attr( 'tabIndex', ($('.atlanta-link-footer')[0].tabIndex+1));
    $('.chicago-link-footer').attr( 'tabIndex', ($('.boston-link-footer')[0].tabIndex+1));
    $('.dallas-link-footer').attr( 'tabIndex', ($('.chicago-link-footer')[0].tabIndex+1));
    $('.dc-link-footer').attr( 'tabIndex', ($('.dallas-link-footer')[0].tabIndex+1));
    $('.la-link-footer').attr( 'tabIndex', ($('.dc-link-footer')[0].tabIndex+1));
    $('.miami-link-footer').attr( 'tabIndex', ($('.la-link-footer')[0].tabIndex+1));
    $('.nyc-link-footer').attr( 'tabIndex', ($('.miami-link-footer')[0].tabIndex+1));
    $('.sfo-link-footer').attr( 'tabIndex', ($('.nyc-link-footer')[0].tabIndex+1));
    $('.seattle-link-footer').attr( 'tabIndex', ($('.sfo-link-footer')[0].tabIndex+1));
    $('.tac-link-footer').attr( 'tabIndex', ($('.seattle-link-footer')[0].tabIndex+1));
    $('.privacy-link-footer').attr( 'tabIndex', ($('.tac-link-footer')[0].tabIndex+1));
    $('.privacy-choices-link-footer').attr( 'tabIndex', ($('.privacy-link-footer')[0].tabIndex+1));
}

function setTabIndexofCarouselCards(prevElement) {
    var activeSlideCards = $('.slick-current').find('.city__carousel__card');
    activeSlideCards.map((index, element) => {
        element.tabIndex = (prevElement.tabIndex+3);
    });
}

function focusFirstCarouselCard(event) {
    setTimeout(() => {
        if(event.which == 13 || event.which == 37 || event.which == 39){
            var activeSlideCards = $('.slick-current').find('.city__carousel__card');
            var firstActiveCard;
            activeSlideCards.map((index, element) => {
                if(index == 0) {
                    firstActiveCard = element;
                }
            });
            firstActiveCard.focus();
        }
    }, 1000)
}