$(document).on('turbolinks:load', function () {
  total_trending_items = 5;
  total_carousels = 1;
  var carousel = [];
  index = 0;
  document.querySelectorAll('.trending-slider').forEach(slider => {
    var gutter = 12.5;
    var items = {
      "default": 1.05,
      "720": 2.1,
      "991": 3.25,
      "1200": 4.25
    }
    carousel[index] = tns({
      "container": slider,
      "slideBy": "page",
      "mouseDrag": true,
      "swipeAngle": false,
      "speed": 400,
      "gutter": gutter,
      "items": items.default,
      "controls": false,
      "nav": false,
      "edgePadding": 10,
      "loop": false,
      "fixedWidth": 320,
      "responsive": {
        "1440": {
          "gutter": 15,
        },
        "1200": {
          "items": items["1200"],
        },
        "991": {
          "items": items["991"],
        },
        "720": {
          "items": items["720"],
        }
      }
    });
    index++;
  });
  if (carousel.length != 0) {
    for (var i = 0; i < total_carousels; i++) {
      var slider = carousel[i];
      var info = slider.getInfo();
      // initialize progress bar properly and on every resize
      updateProgressBar(info, true);
      // handle nav buttons and progress bar on index change
      slider.events.on('indexChanged', function(info, eventName) {
        var parent = $(info.container).parent().parent().parent().parent();
        var left_chevron = parent.find('.chevron-left');
        var right_chevron = parent.find('.chevron-right');
        if (info.displayIndex > (total_trending_items - info.items)) {
          right_chevron.addClass('invisible');
        } else {
          right_chevron.removeClass('invisible');
        }
        if (info.displayIndex <= 1) {
          left_chevron.addClass('invisible');
        } else {
          left_chevron.removeClass('invisible');
        }
        updateProgressBar(info, false);
      });
    }

    $('.chevron-left').on('click', function() {
      var index = parseInt($(this).attr('class').split(' ')[0].split('-')[0]);
      var slider = carousel[index];
      var goToIndex = slider.getInfo().displayIndex-2;
      slider.goTo(goToIndex);
    });
    $('.chevron-right').on('click', function() {
      var index = parseInt($(this).attr('class').split(' ')[0].split('-')[0]);
      var slider = carousel[index];
      var goToIndex = slider.getInfo().displayIndex;
      slider.goTo(goToIndex);
    });

    $('.carousel-row').on('mouseenter', function() {
      $(this).find('.chevron').removeClass('d-none');
    });
    $('.carousel-row').on('mouseleave', function() {
      $(this).find('.chevron').addClass('d-none');
    });
  }
});

function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

function updateProgressBar(info, initialize) {
  var parent = $(info.container).parent().parent().parent().parent();
  if (initialize) {
    // set progress bar width
    var screen_width = $(window).width();
    if (screen_width < 600) {
      $(parent).find('.progress-unit').css('width', (screen_width*0.9)/total_trending_items)
    }
  }
  // activate progress bar links
  parent.find('.progress-unit').removeClass('active');
  var current_index = info.displayIndex-1;
  var visible_elements = range(current_index, current_index + info.items - 1);
  parent.find('.progress-unit').each(function() {
    if ($.inArray($(this).index(), visible_elements) >= 0) {
      $(this).addClass('active');
    }
  });
}
