$(document).ready(function() {

  if(window.location.href.includes('/search') || window.location.href.includes('/restaurant')){
    if($(window).width() < 992){
      $('.navlickJs').removeClass('d-none');
    }
  }
  if(window.location.pathname != '/search'){

    $('.view_all_restaurants').attr('tabindex',27)
    $('.view_map').attr('tabindex',27)
    $('.btn-for-restauranteurs').attr('tabindex',28)
    $('.chevron-left').attr('tabindex', 29);
    $('.chevron-right').attr('tabindex', 29);
    $('.restaurant-card-btn.js-amplitude-download-modal-btn').each(function(index, restaurantCard){
      if(index < 4){
        restaurantCard.tabIndex = 29
      }else{
        restaurantCard.tabIndex = 31
      }

    });
    var home_btns = $('.home-btn').length
    for(let i=0; i< home_btns; i++){
      $('.home-btn')[i].tabIndex = 31+ i
    }
  }
});
$(document).on('turbolinks:load', function() {
  // send app button function
  $('body').on('keypress','.key-press',function( event ) {
    if(event.which == 13 && $(this).attr('name') == 'upload reciept'){
      $('.js-send-btn').click();
    }
    else if(event.which == 13 && $(this).attr('name') == 'search bar'){
      fetchPopularSearches();
      $('.search-box .recent-searches li, .search-box-mobile .recent-searches li').click();
    }
    else if(event.which == 13 && $(this).attr('id') == 'view_map'){
      $('#search-form input[name=view_preference]').val('map');
      submit_form_request();
    }
  });



  // ajax event call when keypress on search bar
  $('input.kba-search-bar').keyup(function(e) {
    e.preventDefault();
    if(e.which == 40){
      $('.search-list').attr('tabindex', '8')
      $('.search-bar').click();
      var tabindex = $('.search-list').attr('tabindex');
      $('.search-list').attr('tabindex', tabindex).focus();
    }
  });

  // handler for search page filters button
  $('.kba-group-btn-sort').keypress(function(event){
    if(event.which == 13){
      var tabindex = parseInt($(this).attr('tabindex'))
      var liSort = $('ul.kba-ul-block-sort>li');
      for(let i=0;i< liSort.length; i++){

        liSort[i].tabIndex = tabindex;

      }
      $('.kb-save-btn').attr('tabindex', tabindex)
    }
  });

  $('.kba-group-btn-neighborhoods').keypress(function(event){
    if(event.which == 13){
      var tabindex = parseInt($(this).attr('tabindex'))
      var liNeigh = $('ul.kba-ul-block-neighborhoods>li');
      for(let i=0;i< liNeigh.length; i++){
        liNeigh[i].tabIndex = tabindex;
      }
      $('.kb-reslt-btn').attr('tabindex', tabindex)
      $('.reset-button').attr('tabindex', tabindex+1)
    }
  });

  $('.kba-group-btn-cuisines').keypress(function(event){
    if(event.which == 13){
      var tabindex = parseInt($(this).attr('tabindex'))
      var liCuisines = $('ul.kba-ul-block-cuisines>li');
      for(let i=0;i< liCuisines.length; i++){
        liCuisines[i].tabIndex = tabindex;
      }
      $('.kb-reslt-btn').attr('tabindex', tabindex)
      $('.reset-button').attr('tabindex', tabindex+1)
    }
  });

  $('.kba-group-btn-price').keypress(function(event){
    if(event.which == 13){
      var tabindex = parseInt($(this).attr('tabindex'))
      var liPrice = $('.kba-ul-block-price>button');
      for(let i=0;i< liPrice.length; i++){
        liPrice[i].tabIndex = tabindex;
      }
      $('.kb-reslt-btn').attr('tabindex', tabindex)
      $('.reset-button').attr('tabindex', tabindex+1)
    }
  });

  $('.kba-group-btn-mood').keypress(function(event){
    if(event.which == 13){
      var tabindex = parseInt($(this).attr('tabindex'))
      var liMood = $('ul.kba-ul-block-mood>li');
      for(let i=0;i< liMood.length; i++){
        liMood[i].tabIndex = tabindex;
      }
      $('.kb-reslt-btn').attr('tabindex', tabindex)
      $('.reset-button').attr('tabindex', tabindex+1)
    }
  });
  // end search page button filters

  // city drop down
  $('.kba-city-drop-down').keypress(function(event){
    if(event.which == 13){
      var tabindex = parseInt($(this).attr('tabindex'))
      var liCity = $('ul#city-list>li');
      for(let i=0;i< liCity.length; i++){
        liCity[i].tabIndex = tabindex;
      }
    }
  });

  // tabindex for download app popup
  $('body').on('keypress','.home-btn,.js-rdp-cta-btn,.btn-download-app', function(e){
    if(e.which == 13){
      $('.text-to-download-input').attr('tabindex', this.tabIndex)
      $('.send-text-btn').attr('tabindex', this.tabIndex)
      $('.js-send-btn-rdp').attr('tabindex', this.tabIndex)
      $('.kba-close').attr('tabindex', this.tabIndex);
      $('.got-it-button-success').attr('tabindex', this.tabIndex)
      $('.sent-close').attr('tabindex', this.tabIndex)
      $('.js-rdp-menu-url').attr('tabindex', this.tabIndex)
      $('.kba-rec-close').attr('tabindex', this.tabIndex)
      $('.menu-btn').attr('tabindex', this.tabIndex);
      $('.QRMOdal__Container__QRBOx').attr('tabindex', this.tabIndex);
    }
  });

  // tbaindex for upload receipt
  $('body').on('keypress','.uploadRecipt', function(e){
    if(e.which == 13){
      $('.kba-upload-receipt').attr('tabindex', this.tabIndex);
      $('.key-press').attr('tabindex', this.tabIndex);
      $('.QRMOdal__Container__QRBOx').attr('tabindex', this.tabIndex);
      $('.kba-rec-close').attr('tabindex', this.tabIndex);
      $('.got-it-button-success').attr('tabindex', this.tabIndex);
      $('.sent-close').attr('tabindex', this.tabIndex);
      $('.text-to-download-input').attr('tabindex', this.tabIndex);
    }
  });

  // tabindex for dine in time slots popup
  $('body').on('keypress','.time-slots-with-rewards', function(e){
    if(e.which == 13){
      $('.kba-text-input').attr('tabindex', this.tabIndex)
      $('.QRMOdal__Container__QRBOx').attr('tabindex', this.tabIndex);
      $('.send-text-btn-rdp-modal').attr('tabindex', this.tabIndex)
      $('.kba-close').attr('tabindex', this.tabIndex)
      $('#downloadmodal .QRMOdal__Container__QRBOx').focus();
    }
  });

  // tbaindex for same day availability
  $('body').on('keypress','.sameDayAvailable', function(e){
    if(e.which == 13){
      $('#sameDayBookingModal .kba-got-it').attr('tabindex', this.tabIndex);
      $('#sameDayBookingModal .kba-close').attr('tabindex', this.tabIndex);
      setTimeout(() => {
        $('.cantBookAdance__contentWrapper__skinBox__button').focus();
      }, 1000)
    }
  });

  // wrdp events for buttons
  $('body').on('keypress','.kba-close,.sent-close,.got-it-button-success', function(e){
    if(e.which == 13){
      if($('.js-rdp-cta-btn').length != 0){
        $('.js-rdp-cta-btn').focus();
      }
    }
  });

  $('body').on('keypress', '#downloadmodal .kba-close', function(e) {
    if(e.which == 13){
      $("#downloadmodal").modal('hide');
    }
  });

  $('body').on('keypress', '#sameDayBookingModal .kba-close,#sameDayBookingModal .kba-got-it', function(e) {
    if(e.which == 13){
      $("#sameDayBookingModal").modal('hide');
      if($(".sameDayDetail").length > 0) {
        $(".sameDayDetail").focus();
      } else {
        var tabIndex = $('#sameDayBookingModal .kba-close').attr('tabindex');
        $(`.sameDayAvailable[tabindex=${tabIndex}]`).focus();
      }
    }
  });

  $('body').on('keypress', '.rdpSlickCarousel__box,.rdp-cta-btn, .sameDayAvailable', function(e) {
    if(e.which == 13){
      $(this).click();
    }
  });

// view all resturants booking section
  $('#view_all_restaurants').keypress(function(e){
    if(e.which == 13){
      $(this).click();
      $('#search_address_input').attr('tabindex', this.tabIndex);

      $('#current_location_container').attr('tabindex', this.tabIndex);
      $('.kba-close').attr('tabindex', this.tabIndex);
      $('#save_location').attr('tabindex', this.tabIndex);
    }
  });


  $('.restaurant-card-btn').keypress(function(e){
    if($('.image-tab') && $('#page_type') != 'home'){
      $('.image-tab').focus();
    }
  });

  $('body').on('keypress','.cross-img,#current_location_container,.dropdown-item,.suggestion-list-item,#view_map,.kba-search-location,.send-text-btn,.send-text-btn-rdp-modal,.list-group-item-action,.kba-search-page-filter,.kb-save-btn,.kb-reslt-btn,.cross-img-address', function(e){
    if(e.which == 13){
      $(this).click()
    }
  });

  var waitForEl = function(selector, callback) {
    if (jQuery(selector).length) {
      callback();
    } else {
      setTimeout(function() {
        waitForEl(selector, callback);
      }, 100);
    }
  };

  $('body').on('focusout','.js-phone-menu-url,.key-press,.kba-download-button,.send-text-btn-rdp-modal,#save_location,.js-send-btn-rdp,.got-it-button-success,.js-menu-url, .QRMOdal__Container__QRBOx, .kba-got-it', function(){
    if(($("#uploadReceiptModal").data('bs.modal') || {})._isShown){
      $('#uploadReceiptModal .close-btn').focus()
    }
    if(($("#downloadModal").data('bs.modal') || {})._isShown){
      $('#downloadModal .close-btn').focus()
    }
    if(($("#downloadmodal").data('bs.modal') || {})._isShown){
      $('.kba-close').focus()
    }
    if(($("#search_address_modal").data('bs.modal') || {})._isShown){
      $('.close-btn').focus()
    }
    if(($("#uploadReceiptRdpModal").data('bs.modal') || {})._isShown){
      $('#uploadReceiptRdpModal .close-btn').focus()
    }
    if(($("#downloadSuccessModal").data('bs.modal') || {})._isShown){
      $('.sent-close').focus()
    }
    if(($("#messageSentRdpModal").data('bs.modal') || {})._isShown){
      $('.close-btn').focus()
    }
    if(($("#phoneOrderModal").data('bs.modal') || {})._isShown){
      $('.kba-rec-close').focus()
    }
    if(($("#sameDayBookingModal").data('bs.modal') || {})._isShown){
      $('.kba-close').focus();
    }
  });

  $('body').on('focusout','.kba-rec-close,.kba-close,.close-btn,.sent-close', function(){
    if(($("#search_address_modal").data('bs.modal') || {})._isShown){
      $('#search_address_input').focus()
    }else if(($("#uploadReceiptRdpModal").data('bs.modal') || {})._isShown){
      $('#uploadReceiptRdpModal .QRMOdal__Container__QRBOx').focus()
    }else if(($("#downloadSuccessModal").data('bs.modal') || {})._isShown){
      $('.got-it-button-success').focus()
    }else if(($("#messageSentRdpModal").data('bs.modal') || {})._isShown){
      $('.js-menu-url').focus()
    }else if(($("#phoneOrderModal").data('bs.modal') || {})._isShown){
      $('.js-phone-menu-url').focus()
    }else if(($("#uploadReceiptModal").data('bs.modal') || {})._isShown){
      $('#uploadReceiptModal .QRMOdal__Container__QRBOx').focus()
    }
    else if(($("#downloadModal").data('bs.modal') || {})._isShown){
      $('#downloadModal .QRMOdal__Container__QRBOx').focus()
    }
    else if(($("#downloadmodal").data('bs.modal') || {})._isShown){
      $('#downloadmodal .QRMOdal__Container__QRBOx').focus()
    }
    else if(($("#sameDayBookingModal").data('bs.modal') || {})._isShown){
      $('#sameDayBookingModal .kba-got-it').focus();
    }
    else{
      $('.kba-text-input').focus()
    }
  });

  /*$('body').on('focusout','.kba-text-input,#search_address_input,.kba-upload-receipt', function(){
    if(($("#uploadReceiptModal").data('bs.modal') || {})._isShown){
      $('.key-press').focus()
    }
    if(($("#downloadModal").data('bs.modal') || {})._isShown){
      $('.kba-download-button').focus()
    }
    if(($("#downloadmodal").data('bs.modal') || {})._isShown){
      $('.send-text-btn-rdp-modal').focus()
    }
    if(($("#search_address_modal").data('bs.modal') || {})._isShown){
      $(this).next().focus()
    }
    if(($("#uploadReceiptRdpModal").data('bs.modal') || {})._isShown){
      $(this).next().focus()
    }
  });*/

  $('body').on('focusout','.js-rdp-menu-url', function(){
   $('.kba-rec-close').focus()
  });

  $('#sliderCarouselGallery').on('focusout','.arrowRight-full-viewer', function(){
    if(($("#sliderCarouselGallery").data('bs.modal') || {})._isShown){
      $('.close-full-viewer').focus()
    }
  });

  $('#sliderCarouselGallery').on('focusout','.close-full-viewer', function(){
    if(($("#sliderCarouselGallery").data('bs.modal') || {})._isShown){
      if($('.leftRight-full-viewer').hasClass('d-none') && $('.arrowRight-full-viewer').hasClass('d-none')) {
        $('.close-full-viewer').focus()
      } else if(!$('.leftRight-full-viewer').hasClass('d-none') && $('.arrowRight-full-viewer').hasClass('d-none')) {
        $('.leftRight-full-viewer').focus()
      } else if($('.leftRight-full-viewer').hasClass('d-none') && !$('.arrowRight-full-viewer').hasClass('d-none')) {
        $('.arrowRight-full-viewer').focus()
      }
    }
  });

  $('#sliderCarouselGallery').on('focusout','.leftRight-full-viewer', function(){
    if(($("#sliderCarouselGallery").data('bs.modal') || {})._isShown){
      if(!$('.leftRight-full-viewer').hasClass('d-none') && $('.arrowRight-full-viewer').hasClass('d-none')) {
        $('.close-full-viewer').focus()
      } else if(!$('.leftRight-full-viewer').hasClass('d-none') && !$('.arrowRight-full-viewer').hasClass('d-none')) {
        $('.arrowRight-full-viewer').focus()
      }
    }
  });

  $('body').on('keypress', '.reset-button',function(e){
    if(e.which == 13){
      $(this).click()
    }
  });

// after close popup return back to controller
  $('body').on('keypress','.close-btn', function(e){
    if(e.which == 13){
      $('.js-delivery-address-field').focus()
    }
  });

  $('body').on('keypress','.delivery-address-item', function(e){
    if(e.which == 13){
      $('.close-btn').attr('tabindex', this.tabIndex);
      $('#search_address_input').attr('tabindex', this.tabIndex);
      $('.cross-img-address').attr('tabindex', this.tabIndex);
      $('#save_location').attr('tabindex', this.tabIndex);
      $('.current-location-container').attr('tabindex', this.tabIndex);
      $(this).click();
    }
  });

  $('body').on('focusin','#event_neighborhood, #event_headcount, #event_company', function(){
    $('.citydropdown_tabHandler').addClass('d-none');
  });

// search page filters focus in and focus out event for handling close and open for dropdown
  $('body').on('focusout', '.kba-download-button', function(e){
    $('.kba-close').focus()
  });

  $('body').on('focusin','#city-drop-down',function(){
    $('.suggestion-box').addClass('d-none')
  });

  $('body').on('focusin','.kba-search-bar,#event_city, #event_type',function(){
    $('.citydropdown_tabHandler').removeClass('d-none');
    $('.suggestion-box').removeClass('d-none')
    $(this).click();

  });

  $('.home-city-dropdown-btn').focusin(function(){
    $('.search-box').addClass('d-none');
  });

  $('.kba-din-in ').focusin(function(){
    $('.city-box').addClass('d-none');
  });

  $('.kba-group-btn-neighborhoods').focusin(function(){
    $(this).addClass('dropdown-toggle:hover')
    $('.filters-desktop-sort').addClass('d-none')
  });

  $('.kba-group-btn-cuisines').focusin(function(){
    $('.filters-desktop-neighborhoods').addClass('d-none')
  });

  $('.kba-group-btn-price').focusin(function(){

    $('.filters-desktop-cuisines').addClass('d-none')
  });

  $('body').on('focusin','.kba-group-btn-mood',function(){

    $('.filters-desktop-price_rating').addClass('d-none')
  });

  $('.kba-din-in').focusin(function(){
    $('.filters-desktop-mood').addClass('d-none')
  });


// assign indexing on page load
  $(document).ready(function() {
    if(window.location.href.includes('/search') || window.location.href.includes('/restaurant')){
      if($(window).width() > 991){
        $('.navlickJs').addClass('d-none');

      }
    }
    if(window.location.pathname != '/search'){

      $('.view_all_restaurants').attr('tabindex',27)
      $('.view_map').attr('tabindex',27)
      $('.btn-for-restauranteurs').attr('tabindex',28)
      $('.chevron-left').attr('tabindex', 29);
      $('.chevron-right').attr('tabindex', 29);
      $('.restaurant-card-btn.js-amplitude-download-modal-btn').each(function(index, restaurantCard){
        if(index < 4){
          restaurantCard.tabIndex = 29
        }else{
          restaurantCard.tabIndex = 31
        }

      });
      var home_btns = $('.home-btn').length
      for(let i=0; i< home_btns; i++){
        $('.home-btn')[i].tabIndex = 31+ i
      }
    }
  });

  $('.restaurant-card-btn.js-amplitude-download-modal-btn').focusin(function(){
    $('.chevron-right').removeClass('d-none');
  });

  $('.chevron-right,.chevron-left').keypress(function(e){
    if(e.which == 13){
      $(this).click()

      $('.chevron-left').removeClass('d-none');
      $('.chevron-left').focus()

    }
  });

  $('body').on('keypress','.close-full-viewer', function(e){
    if(e.which == 13){
      $(this).click();
    }
  });

  $('body').on('keypress','#event_account_requirement, #event_financial_requirement', function(e){
    if(e.which == 13){
      $(this).click();
      e.preventDefault();
    }
  });
});
