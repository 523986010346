$(document).on('turbolinks:load', function() {
  toggleHeaderNav();
  toggleSideBarNav();
  setSideBarActiveLink();
  setFooterTheme();

  $('body').on('click', '.js-delivery-address-item', function() {
    var offering;
    if ($("#search-form input[name='offering']").val() == 'delivery' || $("#current_offering").val() == 'delivery') {
      offering = 'Delivery'
    } else {
      offering = 'Pickup'
    }
    $('#selected_offering').html(offering);
    $('#search_address_modal').modal({ backdrop: 'static' });
  });

  $('#search_address_modal').on('shown.bs.modal', function(){
    $('#search_address_input').focus();
  });

  $('#uploadReceiptRdpModal').on('shown.bs.modal', function(){
    $('#uploadReceiptModal .QRMOdal__Container__QRBOx').first().focus();
  });

  $('#sameDayBookingModal').on('shown.bs.modal', function(e){
    $('#sameDayBookingModal .kba-got-it').focus();
  });

  $('#messageSentRdpModal').on('shown.bs.modal', function(){
    $('.js-menu-url').attr("href", $('#menu_url').val())
  });

  $('body').on('keyup', '#search_address_input', function(){
    if ($(this).val().length > 0) {
      $('.cross-img-address').removeClass('collapse');
    } else {
      $('.cross-img-address').addClass('collapse');
    }
    clearTimeout(suggestionTimer);
    if ($('#search_address_input').val()) {
      suggestionTimer = setTimeout(getSuggestions, 300, $(this));
    }
  });

  $('body').on('click', '.cross-img-address', function() {
    $('.cross-img-address').addClass('collapse');
    $('#search_address_input').val('');
  });

    $('body').on('click', '.js-searched-location-item', function() {
    var address  = '';
    var location = {};
    var element  = $(this);
    if ($(this).hasClass('current-location-container')) {
      address  = 'Current Location';
      var options = { setLocation: true, element: element, address: address };
      setCurrentLocationLatitudeAndLongitude(options);
    } else {
      address  = $(this).find('.js-searched-location-item-field').text();
      location['latitude']  = $(this).find('.js-searched-location-item-field').data('latitude');
      location['longitude'] = $(this).find('.js-searched-location-item-field').data('longitude');
      setSearchLocationValue(element, address, location);
    }
  });

  $('body').on('click', '#save_location', function() {
    var location  = $('#selected_address_field').text();
    if (location) {
      $('.js-delivery-address-field').text(location);
      $('#user_searched_location').val(location);
      var latitude  = $('#selected_address_field').data('latitude');
      var longitude = $('#selected_address_field').data('longitude');
      $('#search-form input[name=latitude]').val(latitude);
      $('#search-form input[name=longitude]').val(longitude);
    }
    $('#search_address_modal').modal('hide');
    if($('#page_type').val() == 'home') {
      // amplitude event start
      var page     = 'home';
      var city     = $('#city-drop-down').data('id');
      var type     = location == 'Current Location' ? 'current_location' : 'user_typed';
      var offering = $('#home_page_booking_types').find('.js-offering-type:checked').val();
      amplitudeAddressEnterEvent(page, city, type, offering);
      // amplitude event end

      submit_form_request();
    }

    else if($('#page_type').val() == 'search') {
      if($('.js-restaurant-display-page').hasClass('d-none')) {
        // amplitude event start
        var page     = 'search';
        var city     = $('#city-drop-down').data('id');
        var type     = location == 'Current Location' ? 'current_location' : 'user_typed';
        var offering = $('#search_page_booking_types').find('.js-offering-type:checked').val();
        amplitudeAddressEnterEvent(page, city, type, offering);
        // amplitude event end

        submit_form_request();
      }
      else {
        // amplitude event start
        var page     = 'restaurant_details';
        var city     = $('#city-drop-down').data('id');
        var type     = location == 'Current Location' ? 'current_location' : 'user_typed';
        var offering = $('#rdp_booking_types').find('.js-offering-type:checked').val();
        amplitudeAddressEnterEvent(page, city, type, offering);
        // amplitude event end
        
        checkDeliveryAvailabilty($('#restaurant_id').val(), $('#current_offering').val());
      }
    }
  });

  function setSearchLocationValue(element, address, location) {
    $('#selected_address_field').text(address);
    $('#selected_address_field').data('latitude', location['latitude']);
    $('#selected_address_field').data('longitude', location['longitude']);
    $('#selected_address_container').removeClass('collapse');
    $('.js-searched-location-item').removeClass('collapse');
    element.addClass('collapse');
  }

  function currentLocationOfUser() {
    var location = {};
    location['latitude']  = $('#search-form input[name=current_location_latitude]').val();
    location['longitude'] = $('#search-form input[name=current_location_longitude]').val();
    return location;
  }

  function isCurrentLocationPresent() {
    return ($('#search-form input[name=current_location_latitude]').val() && $('#search-form input[name=current_location_longitude]').val() ? true : false);
  }

  function setCurrentLocationLatitudeAndLongitude(options) {
    var reEvaluate = !isCurrentLocationPresent();
    if (reEvaluate && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( (position) => {
        $('#search-form input[name=latitude]').val(position.coords.latitude);
        $('#search-form input[name=longitude]').val(position.coords.longitude);
        $('#search-form input[name=current_location_latitude]').val(position.coords.latitude);
        $('#search-form input[name=current_location_longitude]').val(position.coords.longitude);
        options['location'] = currentLocationOfUser();
        setSearchLocationValue(options['element'], options['address'], options['location']);
      }, () => {
        var currentCity = citiesWithLocation($('.home-city-dropdown-btn').data('id'));
        $('#search-form input[name=latitude]').val(currentCity['latitude']);
        $('#search-form input[name=longitude]').val(currentCity['longitude']);
        $('#search-form input[name=current_location_latitude]').val(currentCity['latitude']);
        $('#search-form input[name=current_location_longitude]').val(currentCity['longitude']);
        options['location'] = currentLocationOfUser();
        setSearchLocationValue(options['element'], options['address'], options['location']);
      }
    )} else {
      options['location'] = currentLocationOfUser();
      setSearchLocationValue(options['element'], options['address'], options['location']);
    }
  }
});

//Global Variable
var suggestionTimer;


function citiesWithLocation(id) {
  axios.get('/api/search/cities').then(response => {
    let allCities = response.data;
    return allCities[id];
  }).catch(error => {
    console.log(error);
  });
}

window.defaultSearchParams = function() {
  var restaurantsType            = $('#search-form input[name=restaurantsType]').val();
  var offering                   = $("#search-form input[name='offering']").val();
  var latitude                   = $('#search-form input[name=latitude]').val();
  var longitude                  = $('#search-form input[name=longitude]').val();
  var current_location_latitude  = $('#search-form input[name=current_location_latitude]').val();
  var current_location_longitude = $('#search-form input[name=current_location_longitude]').val();
  var time_slot                  = $('#search-form input[name=time_slot]').val($("#time_picker").val());
  var searchedLocation           = $('#user_searched_location').val();
  var viewPreference             = $('#search-form input[name=view_preference]').val();
  return { latitude: latitude, longitude: longitude, current_location_latitude: current_location_latitude, current_location_longitude: current_location_longitude, offering: offering, restaurantsType: restaurantsType, searchedLocation: searchedLocation, viewPreference: viewPreference, date: time_slot}
}

function setTabIndexToLocationItems(index_number){
  var liAddress = $('#searched_location_items_container>div')
  for(let i=0;i< liAddress.length; i++){
    liAddress[i].querySelector('div>.col-11').tabIndex = index_number;
    liAddress[i].querySelector('div>.col-11').onkeypress = function(e){
      if(e.which == 13){
        this.click();
        $('#save_location').focus()
      }
    }
    $('.kba-close').attr('tabindex', index_number);     
  }
}

window.getLatitudeLongitude = function() {
  var latitude  = $('#search-form input[name=latitude]').val();
  var longitude = $('#search-form input[name=longitude]').val();
  return { latitude: latitude, longitude: longitude }
}

window.getSuggestions = function (element){
  var query = element.val();
  if (query.length > 2) {
    element.removeClass('search-bar-img').addClass('search-arrow-img');
    $('#current_location_container').removeClass('solo-location');
    var params        = {};
    params['query']   = query;
    params['city_id'] = $('.home-city-dropdown-btn').data('id');
    $.ajax({
      type: 'GET',
      url: '/search/location_preferences.js',
      data: params,
      success: function(){
        var index = $('#current_location_container').attr('tabindex');
        if(index){
          setTabIndexToLocationItems(index)
        }
      }
    });
  } else {
    element.removeClass('search-arrow-img').addClass('search-bar-img');
  }
}

window.closeSearchBar = function () {
  $('.city-box, .mobile-search, .search-box-mobile, .suggestion-box-mobile').addClass("d-none");
  $('.search-box').removeClass('slide-down').addClass('slide-up');
  if (!$('.suggestion-box').hasClass('d-none')) {
    $('.suggestion-box').removeClass('slide-down').addClass('slide-up');
  }
  setTimeout(function() {
    $('.search-bar, .search-bar-container').removeClass("search-list-visible");
    $('.search-bar-container').addClass('bg-offwhite border').removeClass('shadow');
  }, 800);
  $('.home-city-dropdown-btn').addClass('border').removeClass("home-btn-click");
  $('.welcome-banner, .app-caption, #restaurant-slider, #header, .find-a-spot, .take-a-seat, .get-takeout-and-delivery, .get-reward, .footer').removeClass("d-none");
  if ($('.restaurant-display-page').hasClass('d-none') && $('.review-page').hasClass('d-none')) {
    $('#search-filters-desktop').removeClass('d-none');
  }
  $('.search-results').removeClass('d-none');
}