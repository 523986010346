$(document).on('turbolinks:load', function () {
  var carousel = [];
  index = 0;
  document.querySelectorAll('.overall-restaurants-slider').forEach(slider => {
    var gutter = 50;
    var items = {
      "default": 1,
      "991": 1.5
    }
    carousel[index] = tns({
      "container": slider,
      "slideBy": "page",
      "mouseDrag": true,
      "swipeAngle": false,
      "speed": 400,
      "gutter": gutter,
      "items": items.default,
      "controls": false,
      "nav": false,
      "edgePadding": 20,
      "loop": false,
      "responsive": {
        "991": {
          "items": items["991"],
          "edgePadding": 20
        }
      }
    });
    index++;
  });
  if (carousel.length != 0) {
    for (var i = 0; i < 3; i++) {
      var slider = carousel[i];
      slider.events.on('indexChanged', function(info, eventName) {
        var parent = $(info.container).parent().parent().parent().parent();
        var left_chevron = parent.find('.chevron-left');
        var right_chevron = parent.find('.chevron-right');
        if (info.displayIndex >= 4) {
          right_chevron.addClass('invisible');
        } else {
          right_chevron.removeClass('invisible');
        }
        if (info.displayIndex <= 1) {
          left_chevron.addClass('invisible');
        } else {
          left_chevron.removeClass('invisible');
        }
        parent.find('.progress-unit').removeClass('active');
        var current_index = info.displayIndex-1;
        parent.find('.unit-'+ current_index).addClass('active');
      });
    }
    
    $('.chevron-left').on('click', function() {
      var index = parseInt($(this).attr('class').split(' ')[0].split('-')[0]);
      var slider = carousel[index];
      var goToIndex = slider.getInfo().displayIndex-2;
      slider.goTo(goToIndex);
    });
    $('.chevron-right').on('click', function() {
      var index = parseInt($(this).attr('class').split(' ')[0].split('-')[0]);
      var slider = carousel[index];
      var goToIndex = slider.getInfo().displayIndex;
      slider.goTo(goToIndex);
    });

    $('#new-york-city').collapse('show');

    $('.restaurants-dropdown .dropdown-menu a').on('click', function(e) {
      e.preventDefault();
      $('.restaurants-dropdown .dropdown-menu').collapse('hide');
      var data = $(this).data('value');
      var dataName = $(this).text();
      if (!$('#'+data).hasClass('show')) {
        $('.all-mobile-res').collapse('hide');
        $('#'+data).collapse('show');
        $('.restaurants-dropdown .dropdown-toggle').text(dataName);
      }
    })
    
    $('.carousel-row').on('mouseenter', function() {
      $(this).find('.chevron').removeClass('d-none');
    });
    $('.carousel-row').on('mouseleave', function() {
      $(this).find('.chevron').addClass('d-none');
    });
  }
});
