  const { contains } = require("jquery");

  var globalData = {};
  global.setGlobalData = function(key, value){
    globalData[key] = value;
  }

  global.getGlobalData = function(key){
    return globalData[key];
  }

  global.setMapOnAll = function(map) {
    markers = getGlobalData('markers');
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  global.clearMarkers = function() {
    setMapOnAll(null);
  }

  global.deleteMarkers = function() {
    clearMarkers();
    setGlobalData('markers', []);
  }

  cityBtnDev = 30;
  mobileWidth = 576;

  function adjustLogoBoxes() {
    var width = $('.logo-box').outerWidth();
    $('.logo-box').height(width*0.77);
    if ($(window).width() < 992) {
      $('.row .logo-box').eq(0).addClass('ml-0');
      $('.row .logo-box').eq(3).addClass('ml-0');
    } else {
      $('.row .logo-box').eq(0).removeClass('ml-0');
      $('.row .logo-box').eq(3).removeClass('ml-0');
    }
  }
  function resizeSearchDropdown() {
      if ($('.search-bar-container').length == 0) {
        return;
      }
      closeSearchBar();
      $('.search-box > .inner-box, .suggestion-box > .inner-box').width($('.search-bar-container').outerWidth());
      var rightSpace = 20;
      var windowWidth = $(window).width();
      var leftSpace, searchPageLeftSpace;
      if (windowWidth > 991 && windowWidth < 1200) {
          leftSpace = $('.search-bar-container').innerWidth() + $('.search-bar-container').position().left - rightSpace;
          searchPageLeftSpace = leftSpace - rightSpace*2;
      } else {
          if(windowWidth >=1250){
              rightSpace = 0;
          } else if (windowWidth >= 1200) {
              rightSpace = 25;
          } else if (windowWidth > 500) {
              rightSpace = 0;
          } else {
            rightSpace = 5;
          }
          leftSpace = $('.search-bar-container').width() + $('.search-bar-container').position().left + rightSpace;
          searchPageLeftSpace = leftSpace - rightSpace;
      }
      $('.welcome-banner .home-city-dropdown-btn').css('left', leftSpace);
      $('.welcome-banner .city-box').css('left', leftSpace - $('.welcome-banner .city-box').outerWidth() + $('.welcome-banner .home-city-dropdown-btn').outerWidth() + 15);
      $('#search-filters-desktop .home-city-dropdown-btn').css('left', searchPageLeftSpace);
      $('#search-filters-desktop .city-box').css('left', searchPageLeftSpace - $('#search-filters-desktop .city-box').outerWidth() + 2*$('#search-filters-desktop .home-city-dropdown-btn').outerWidth());
      $('.home-city-dropdown-btn').removeClass('invisible');
  }

  function changeSearchBarPlaceholder() {
    $(".search-bar").attr('placeholder', 'Search');
  }

  function getSearchRecommendations(city, query, time_slot, maxSeat) {
    axios.get('/api/search/recommendations', {
      headers: {
        // 'Token': token
      },
      params: {
        'city_id': city,
        'query': query,
        'time_slot': time_slot,
        'maxSeat': maxSeat
      }
    }).then(response => {
      if ($(window).width() > mobileWidth) {
        $('.suggestion-box').removeClass("d-none");
        $('.search-box').addClass("d-none");
        $('.search-bar, .search-bar-container').addClass("search-list-visible");
        $('.suggestion-box > .inner-box').html(response.data.data);
      } else {
        $('.suggestion-box-mobile').removeClass("d-none");
        $('.search-box-mobile').addClass("d-none");
        $('.suggestion-box-mobile > .inner-box').html(response.data.data);
      }
      //Event Name - search_cta
      searchQueryResponseTracking('search_cta', $('#page_type').val(), query, null, response.data.restaurant_count, 'web')
    }).catch(error => {
      console.log(error);
    });
  }

  function fetchPopularSearches() {
    var cityId = $('#city-drop-down').data('id');
    axios.get('api/search/popular', {
      headers: {
        // 'Token': window.token
      },
      params: { cityId: cityId }
    }).then(response => {
      var popularSearches = response.data;
      var popularSearchListItems = $('.search-box .popular-searches li');
      var popularSearchListItemsMobile = $('.search-box-mobile .popular-searches li');
      for (var searchItem = 0; searchItem < popularSearches.length; searchItem++) {
        $(popularSearchListItems[searchItem]).text(popularSearches[searchItem]);
        $(popularSearchListItemsMobile[searchItem]).text(popularSearches[searchItem]);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  window.toggleHeaderNav = function () {
    if($('#page_type').val() == "home") {
      $(window).scroll(function() {
        var header = document.getElementById("nav_header");
        var sticky = header.offsetTop;
        if ($(window).scrollTop() > sticky) {
            header.classList.add("hp__section__header__sticky");
          } else {
            header.classList.remove("hp__section__header__sticky");
        }
      });
    } else {
      $(window).scroll(function() {
        var header = document.getElementById("nav_header");
        if(header) {
          var sticky = header.offsetTop;
          if (window.pageYOffset > sticky) {
              header.classList.add("hp__section__header__sticky");
              if (window.location.pathname == '/user-terms' || window.location.pathname == '/privacy' || window.location.pathname == '/event-planning-form' || window.location.pathname == '/event-planning' || window.location.pathname == '/frequently-asked-questions' || window.location.pathname == '/helpout') {
                header.classList.add("hp__section__headerLight__sticky");
              }
            } else {
              header.classList.remove("hp__section__header__sticky");
              if (window.location.pathname == '/user-terms' || window.location.pathname == '/privacy' || window.location.pathname == '/event-planning-form' || window.location.pathname == '/event-planning' || window.location.pathname == '/frequently-asked-questions' || window.location.pathname == '/helpout') {
                header.classList.remove("hp__section__headerLight__sticky");
              }
          }
        }
      });
    }
  };

  window.setFooterTheme = function () {
    var footer = document.getElementById("main-footer");
    if(footer){
      if($('#page_type').val() == "home") {
        footer.classList.add("footer_dark");
      } else {
        footer.classList.add("footer_light");
      }
    }
  };

  window.toggleSideBarNav = function () {
        // Side Nav Hide and show
        $(".hp__main__header__burgerButton").on("click", function(){
          $(".sideNav__container").addClass("sideNav__active");
          $(".sideNav__container").addClass("sideNav__animationContent");
          $(".hp__main").addClass("overflow__hidden");
          $("body").addClass("overflow__hidden");
        });

        $(".sideNav__container").on("click", function(e){
          let clicked = e.target.closest(".sideNav__content");
          if(!clicked){
            $(".sideNav__container").removeClass("sideNav__animationContent");

            setTimeout(function(){
              $(".sideNav__container").removeClass("sideNav__active");
              $(".hp__main").removeClass("overflow__hidden");
              $("body").removeClass("overflow__hidden");
            }, 250)
          }
        })
  };

  window.setSideBarActiveLink = function () {
    const urlParams = new URLSearchParams(window.location.search);

    if(window.location.pathname == '/search' && urlParams.get("offering") == 'dine_in') {
      $('.sideBarDineInLink').addClass("sideNav__linkActive");
    } else if(window.location.pathname == '/search' && urlParams.get("offering") == 'delivery') {
      $('.sideBarDeliveryLink').addClass("sideNav__linkActive");
    } else if(window.location.pathname == '/event-planning') {
      $('a[href="' + '/event-planning' + '"]').addClass("sideNav__linkActive");
    } else if(window.location.pathname == '/https://restaurants.getseated.com/') {
      $('a[href="' + '/https://restaurants.getseated.com/' + '"]').addClass("sideNav__linkActive");
    }
  };

  window.showGtaCTA = function () {
    if(screen.width <= 768){
      $(".gta-desktop-cta").addClass("d-none");
      $(".gta-mobile-cta").removeClass("d-none");
    }
  };

  window.isInViewport = function(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  $(document).on('turbolinks:before-visit', function() {
    $(".full_screen_animation").removeClass("hp__bottomToTopAnimationFullScreen");
    $(".sideNav__container").removeClass("sideNav__active");
    if ($('.hp__logos__wrapper').length > 0 && $('.hp__logos__wrapper').hasClass("slick-slider")) {
      $('.hp__logos__wrapper').slick('unslick');
    }
    if ($('.hp__section__howWork__slider').length > 0 && $('.hp__section__howWork__slider').hasClass("slick-slider")) {
      $('.hp__section__howWork__slider').slick('unslick');
    }
    if ($('.reviewSliderMobile').length > 0 && $('.reviewSliderMobile').hasClass("slick-slider")) {
      $('.reviewSliderMobile').slick('unslick');
    }
  });

  $(document).on('turbolinks:load', function() {
    // new homepage design starting
    toggleHeaderNav();
    toggleSideBarNav();
    setSideBarActiveLink();
    setFooterTheme();
    showGtaCTA();

    window.addEventListener('popstate', function(event) {
      if($('#page_type').val() == "home") {
        $(".full_screen_animation").removeClass("hp__bottomToTopAnimationFullScreen");
      }
      if ($('.hp__logos__wrapper').length > 0 && $('.hp__logos__wrapper').hasClass("slick-slider")) {
        $('.hp__logos__wrapper').slick('unslick');
      }
      if ($('.hp__section__howWork__slider').length > 0 && $('.hp__section__howWork__slider').hasClass("slick-slider")) {
        $('.hp__section__howWork__slider').slick('unslick');
      }
      if ($('.reviewSliderMobile').length > 0 && $('.reviewSliderMobile').hasClass("slick-slider")) {
        $('.reviewSliderMobile').slick('unslick');
      }
    });

    if($('#page_type').val() == "home") {
      var slider = $(".hp__section__howWork__slider");
      document.addEventListener('scroll', function (event) {
        if(isInViewport(slider[0]) && slider.hasClass("slick-initialized")) {
          var autoplayValue = $('.hp__section__howWork__slider').slick("slickGetOption", "autoplay");
          if(autoplayValue == false) {
            $('.hp__section__howWork__slider').slick("slickSetOption", "autoplay", true, true);
          }
        }
      }, true);

      setTimeout(() => {
        $("#splashScreen").addClass("d-none");
        $(".hp__main").removeClass("d-none");
        $(".full_screen_animation").addClass("hp__bottomToTopAnimationFullScreen");
      }, 2500);
    };
    var removeModalRightSpacing = function(){
      $('body').css("padding-right", "0");
      $('header.sticky-top').css("padding-right", "0");
      $('header.sticky-top').css("margin-right", "0");
    };

    $('#cityVideoModal').on('hidden.bs.modal', function () {
      removeModalRightSpacing();
    });

    $('#cityVideoModal').on('shown.bs.modal', function () {
      if(screen.width <= 720) {
      $("#cityVideoModal .for__mobileVideoModal").trigger('play');
      } else {
      $("#cityVideoModal .for__desktopVideoModal").trigger('play');
      }
     });
    $('#cityVideoModal').on('hidden.bs.modal', function () {
        if(screen.width <= 720) {
        $("#cityVideoModal .for__mobileVideoModal").trigger('pause');
        } else {
        $("#cityVideoModal .for__desktopVideoModal").trigger('pause');
        }
    });
    // lazy load images
    try {
      $("img.lazy").Lazy({
        delay: 500
      });
    } catch (err) {
      console.log('Lazy Loading not initialized');
    }

    // logo boxes in the get rewards section.
    // revisit this as this may no longer be needed.
    adjustLogoBoxes();

    var width = $(window).width();
    $(window).resize(function(){
      if($(this).width() != width){
        width = $(this).width();
        adjustLogoBoxes();
        resizeSearchDropdown();
      }
      changeSearchBarPlaceholder();
    });

    // if search box exists, resize the search bar and the search bar accordingly.
    if ($('.search-box').length > 0) {
      resizeSearchDropdown();
      changeSearchBarPlaceholder();
      fetchPopularSearches();
    }
    //fetchPopularSearches();
    //fetchToken();

    $('.search-bar').click(function (e) {
      if(e.bubbles){
        e.stopPropagation();
        return;
      }
      $('.filters-desktop').addClass('d-none');
      $('.home-city-dropdown-btn').removeClass('home-btn-click');
      $('.home-city-dropdown-btn').addClass('border');
      var desktopScreen = $(window).width() > mobileWidth;
      var searchPage = false;
      if ($(this).parents('#search-filters-desktop').length) {
        searchPage = true;
      }
      if (searchPage && !desktopScreen) {
        // return;
      }
      if (desktopScreen) {
        $('.search-bar-container').addClass('shadow').removeClass('border');
        $('.search-box').removeClass("d-none");
        $('.city-box').addClass("d-none");
      } else {
        if (searchPage) {
          $('#header, #search-filters-desktop, .search-results, .footer').addClass('d-none');
        } else {
          $('#header, .welcome-banner, #restaurant-slider, .app-caption, .find-a-spot, .take-a-seat, .get-takeout-and-delivery, .get-reward, .footer').addClass("d-none");
        }
        $('.mobile-search').removeClass("d-none");
        $('.search-box-mobile').removeClass("d-none");
        $('.mobile-search-bar').focus();
        $('.mobile-search-bar').val($('.search-bar').val());
      }
      $('.search-bar, .search-bar-container').addClass("search-list-visible");
      e.stopPropagation();
      //Event Name - search_bar_tap
      searchBarClickTracking('search_bar_tap', $('#page_type').val(), null, 'web', null);
      }
    )


    // close city dropdown when clicking on the search box.
    $('.search-box > .inner-box, .suggestion-box > .inner-box').click(function (e){
      $('.city-box').addClass("d-none");
      $('.home-city-dropdown-btn').addClass("border");
      e.stopPropagation();
    });

    $('.search-box-mobile > .inner-box, .suggestion-box-mobile > .inner-box').click(function (e) {
        e.stopPropagation();
      }
    );

    $('.search-bar').click(function() {
      $('.search-box').removeClass('slide-up').addClass('slide-down');
    });

    // close search bar when clicking outside it.
    $(document).click(function (){
        closeSearchBar();
      }
    )

    $('.home-city-dropdown-btn').click(function (e){
      if ($(window).width() > 576) {
        $('.city-box').toggleClass("d-none");
        $(this).toggleClass("home-btn-click").toggleClass("border");
        $('.search-box').addClass("d-none");
        $('.suggestion-box').addClass("d-none")
        $('.search-bar, .search-bar-container').removeClass("search-list-visible");
        e.stopPropagation();
      } else {
        $('#cityModal').modal();
      }
    });

    // search box closed.
    $('body').on('click', '.cross-img, .cross-img-mobile', function(e){
      $('.cross-img').hide();
      $(this).parents().find('.search-bar').val('');
      if($(this).parents().find('#search-filters-desktop').length) {
        $('#search-form input[name=query]').val('');
        $('#search-form input[name=city]').val($(this).parent().find('.home-city-dropdown-btn').data().id);
        $('#search-form input[name=time_slot]').val($("#time_picker").val());
        $('#search-form input[name=maxSeat]').val($("#maxSeatGuest").text());
        $('#search-form').submit();
      } else {
        closeSearchBar();
        e.stopPropagation();
      }
    });

    $('body').on('keyup', '.search-bar', function(e){
      if (!$(this).hasClass('mobile-search-bar')) {
        if ($(this).val() == '') {
          $('.cross-img').hide();
        } else {
          $('.cross-img').show();
        }
      }
      var city = $('.home-city-dropdown-btn').first().data().id;
      var time_slot = $("#time_picker").val();
      var maxSeat = $("#maxSeatGuest").text();
      if ($(window).width() > mobileWidth) {
        if (e.keyCode == 8 && e.currentTarget.value.length < 3) {
          $('.suggestion-box').addClass("d-none");
          $('.search-box').removeClass("d-none");
          $('.search-bar, .search-bar-container').addClass("search-list-visible");
        } else {
          var query = $('.search-bar').first().val();
          if (query.length >= 3) {
            getSearchRecommendations(city, query, time_slot, maxSeat);
            $('.suggestion-box').removeClass('slide-up').addClass('slide-down');
          } else {

          }
        }
      } else{
        if (e.keyCode == 8 && e.currentTarget.value.length < 3) {
          $('.search-box-mobile').removeClass("d-none");
          $('.suggestion-box-mobile').addClass("d-none");
        } else {
          var query = $('.search-bar').last().val();
          if (query.length >= 3) {
            getSearchRecommendations(city, query, time_slot, maxSeat);
          }
        }
      }
      if (e.keyCode == 13) {
        if ($('#search-filters-desktop').length) {
          // search page
          submit_form_request();
        } else {
          // home page
          // amplitude event start
          if($('#page_type').val() == 'home') {
            var cityId     = $('#city-drop-down').data('id');
            var time_slot = $("#time_picker").val();
            var maxSeat = $("#maxSeatGuest").text();
            var offering   = $('#home_page_booking_types').find('.js-offering-type:checked').val();
            var source     = 'text_search';
            var searchText = $('.search-bar').val();
            amplitudeSearchFromHomeEvent(cityId, offering, source, searchText, time_slot, maxSeat);
          }
          // amplitude event end
          searchAllByQuery(this);
        }
      }
    });

    // select city from city dropdown or modal.
    var selectedCity = "NYC";
    var oldCity = selectedCity;

    $('ul#city-list li, #cityModal li').click(function () {
        var newCity = $(this).attr('value');
        var id = $(this).data().id;
        $('.home-city-dropdown-btn').each(function() {
          $(this).data().id = id;
        });

      amplitudeCityToggleEvent(selectedCity, newCity);
      //Event Name - city_change
      cityChangedEvent('city_change', selectedCity, newCity, 'web');
        selectedCity = newCity;
        $('.home-city-dropdown-btn').text(newCity);
        $('.city-list-item').removeClass("current-city-image");
        $('.'+newCity+'-list-item').addClass("current-city-image");
    })

    $('#cityModal .cancel-btn').click(function() {
      $('.home-city-dropdown-btn').text(oldCity);
      $('.city-list-item').removeClass("current-city-image");
      $('.'+oldCity+'-list-item').addClass("current-city-image");
    });
    // handle search box list item clicks
    $('.search-box .popular-searches li, .search-box-mobile .popular-searches li').click(function(e) {
      spacePattern = new RegExp(" ", "g");
      var mood = $(this).text().trim().toLowerCase().replace(spacePattern, '_');
      $('#search-form input[name=filters]').val(mood+'-mood');
      var parent = $('#search-filters-desktop');
      if ($('.welcome-banner').length) {
        parent = $('.welcome-banner');
      }
      // Event Name - popular_search_tap
      popularSearchSelected('popular_search_tap', null, Array.from(this.parentElement.children).indexOf(this) + 1, null, null, $('#page_type').val(), 'web' , 3);

      $('#search-form input[name=city]').val(parent.find('.home-city-dropdown-btn').data().id);
      $('#search-form input[name=time_slot]').val($("#time_picker").val());
      $('#search-form input[name=maxSeat]').val($("#maxSeatGuest").text());
      defaultSearchParams();
      $('#search-form').submit();
      e.stopPropagation();
    });
    // if($('#page_type').length && $('#page_type').val() == 'home') {
    //   $(".cityListJs[data-id="+$('#home-city-id').data().id+"]").addClass('active-element');
    // }
    if($('#selected_time').length) {
      $(".timeSlotJs[data-value='"+$('#selected_time').data().value.trim() +"']").addClass('active-element');
    }
    if($('#maxSeatGuest').length) {
      $(".guestListJs[data-id='"+$('#maxSeatGuest').text()+"']").addClass('active-element');
    }

    $('body').on('change', '#home_page_booking_types .js-offering-type', function() {
      $('#view_map').addClass('d-none');
      $('#view_all_restaurants').addClass('d-none');

      var oldOffering = $("#search-form input[name='offering']").val();
      var newOffering = $(this).val();
      amplitudeOfferingToggleEvent('home', oldOffering, newOffering);

      // Event Name - product_bar
      productBarClicked('product_bar', null, null, $('#page_type').val(), 'web', oldOffering, newOffering);

      if ($(this).val() == 'delivery') {
        $('.js-delivery-address-container').removeClass('collapse');
        $('#view_all_restaurants').removeClass('d-none');
      }
      else if ($(this).val() == 'pickup'){
        $('.js-delivery-address-container').removeClass('collapse');
        $('#view_map').removeClass('d-none');
      }
      else {
        $('.js-delivery-address-container').addClass('collapse');
        $('#view_map').removeClass('d-none');
      }
      $("#search-form input[name='offering']").val($(this).val());
    });

    $('body').on('click', '#view_all_restaurants', function() {
      $('#search-form input[name=view_preference]').val('list');
      submit_form_request();
    });

    $('body').on('click', '#view_map', function() {
      $('#search-form input[name=view_preference]').val('map');
      submit_form_request();
    });

    $('body').on('click','.startBrowsingJs', function(){
      $('#search-form input[name=query]').val('');
      $('#search-form input[name=city]').val($(".cityListJs[data-value='"+$('#home-city-id').text().trim()+"']").data().id);
      $('#search-form input[name=time_slot]').val($("#time_picker").val());
      $('#search-form input[name=maxSeat]').val($("#maxSeatGuest").text());
      $('#search-form').submit();
    });

    $('.NYCButtonContainer').keypress(function(event){
      if(event.which == 13){
        $(this).click();
        var tabindex = parseInt($(this).attr('tabindex'))
        var liCity = $('.NYCContentContainer__ul>li');
        for(let i=0;i< liCity.length; i++){
          liCity[i].tabIndex = tabindex+1;
        }
      }
    });
    $('.GuestButtonContainer').keypress(function(){
      if(event.which == 13){
        $(this).click();
        var tabindex = parseInt($(this).attr('tabindex'))
        var liCity = $('.GuestContentContainer__ul>li');
        for(let i=0;i< liCity.length; i++){
          liCity[i].tabIndex = tabindex+1;
        }
      }
    });

    $('.DateButtonContainer').keypress(function(){
      if(event.which == 13){
        $(this).click();
      }
    });
    $('.TimeButtonContainer').keypress(function(){
      if(event.which == 13){
        $(this).click();
        var tabindex = parseInt($(this).attr('tabindex'))
        var liCity = $('.TimeContentContainer__ul>li');
        for(let i=0;i< liCity.length; i++){
          liCity[i].tabIndex = tabindex+1;
        }
      }
    });
    const dropDownBoxContainer = document.querySelectorAll(".dropDownBoxContainer");
    const dropDownContentContainer = document.querySelectorAll(".dropDownContentContainer");

    const backdrop__layout = document.querySelector(".backdrop__layout");
    const rowDropDownContainer = document.querySelector(".rowDropDownContainer");


    $('body').on('click','.cityListJs', function(){
      $("#home-city-id").attr('data-id', $(this).data().id);
      $('#home-city-id').text($(this).data().value);
      $('.NYCContentContainer__ul > li').removeClass('active-element');
      $(this).addClass('active-element');
      cancelDropDown();
    });

    $('body').on('click','.guestListJs', function(){

      $('#maxSeatGuest').text($(this).data().id);
      $('.GuestContentContainer__ul>li').removeClass('active-element');
      $(this).addClass('active-element');
      if($(this).hasClass('makeJsRequest')){
        $('.search-bar').trigger($.Event( "keyup", { keyCode: 13 } ));
      }
      cancelDropDown();
    });

    $('body').on('keypress','.guestListJs,.cityListJs,.timeSlotJs,.startBrowsingJs,.kba-plan-event',function(event){
      if(event.which == 13){
        $(this).click();
      }
    });

    $('body').on('focusin','.kba-rest-card', function(e){
      cancelDropDown()
    });

    $('body').on('click','.timeSlotJs', function(){

      $('#selected_time').text($(this).text().trim());
      $('#selected_time').attr('data-value', $(this).text().trim());
      $('#selected_time').text($(this).text().trim());
      $('#selected_time').attr('data-value', $(this).text().trim());
      $('#time_picker').val([$('#time_picker').val().trim().split(' ')[0], $(this).text().trim()].join(' '));
      $('.TimeContentContainer__ul > li').removeClass('active-element');
      $(this).addClass('active-element');
      if($(this).hasClass('makeJsRequest')){
        $('.search-bar').trigger($.Event( "keyup", { keyCode: 13 } ));
      }
      cancelDropDown();
    });

    $('body').on('click','.hidePicker', function(){

      $('.rowDropDownContainer').addClass('d-none');
      $('.browsingCont').addClass('d-none');
      if($(this).hasClass('kba-pickup')){
        $('.viewMapJs').removeClass('d-none');
      }else{
        $('.viewMapJs').addClass('d-none');
      }

    });

    $('body').on('click','.showPicker', function(){
      $('.viewMapJs').addClass('d-none');
      $('.browsingCont').removeClass('d-none');
      if($('.rowDropDownContainer')){
        $('.rowDropDownContainer').removeClass('d-none');
      }
      if($('.searchWeb')){
        $('.searchWeb').removeClass('d-none');
      }
    });

    $('.NYCButtonContainer,.GuestButtonContainer,.DateButtonContainer,.TimeButtonContainer,.startBrowsingJs').focusin(function(){
      cancelDropDown();
    });
    function cancelDropDown(){
      dropDownBoxContainer.forEach(box => box.classList.remove("dropDownBoxContainer__click"));
      dropDownContentContainer.forEach(content => content.classList.remove("dropDownContentContainer__click"));
      backdrop__layout.classList.remove("backdrop__layout__active");
    }

    if(rowDropDownContainer){
      rowDropDownContainer.addEventListener("click", function(e){
        let itemClicked = e.target.closest(".dropDownBoxContainer");

        if(!itemClicked) return;

        if(itemClicked.classList.contains("dropDownBoxContainer__click")){
          itemClicked.classList.toggle("dropDownBoxContainer__click");

          document.querySelector(`.dropDownContentContainer__click__${itemClicked.dataset.tabactive}`).classList.toggle("dropDownContentContainer__click");

          backdrop__layout.classList.remove("backdrop__layout__active");

        }else{
          dropDownBoxContainer.forEach(box => box.classList.remove("dropDownBoxContainer__click"));
          itemClicked.classList.add("dropDownBoxContainer__click");

          dropDownContentContainer.forEach(content => content.classList.remove("dropDownContentContainer__click"));
          document.querySelector(`.dropDownContentContainer__click__${itemClicked.dataset.tabactive}`).classList.add("dropDownContentContainer__click");

          backdrop__layout.classList.add("backdrop__layout__active");

        }


        backdrop__layout.addEventListener("click", function(){
          dropDownBoxContainer.forEach(box => box.classList.remove("dropDownBoxContainer__click"));
          dropDownContentContainer.forEach(content => content.classList.remove("dropDownContentContainer__click"));
          backdrop__layout.classList.remove("backdrop__layout__active");
        })

      })
    }
    $('.rd-next,.rd-back').click(function(){
      $('.rd-day-body').click(function(){
        cancelDropDown();
      });
  });

  $('.rd-day-body').click(function(){
    cancelDropDown();
  });
  });
