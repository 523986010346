(function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode".split(" "), 0);

BRANCH_KEY = 'key_live_nazwNejWyNdO5F92qQqPMjpjFrm6jLSv'
let PROMO_REWARD = 10
function sendSMS(phone, customText, errorElement, clickedFrom) {
  var linkData = {
    tags: [],
    channel: 'Website',
    feature: 'TextMeTheApp',
    data: {
      $custom_sms_text: customText,
      'foo': 'bar'
    }
  };
  var options = {};
  var callback = function(err, result) {
    if (err) {
      errorElement.text('Invalid Mobile Number').removeClass('d-none');
      errorElement.parent().find('.number-invalid-icon').removeClass('d-none');
      phoneNoEnteredErrorTracking('Invalid Mobile Number');
    }
    else {
      if (clickedFrom == 'rdp-modal') {
        $('#downloadmodal').modal('hide');
        $('#downloadSuccessModal').modal();
      } else {
        $('#downloadModal').modal('hide');
        $('#downloadSuccessModal').modal();
      }
      errorElement.text('REQUIRED').addClass('d-none');
      errorElement.parent().find('.number-invalid-icon').addClass('d-none');
      $('.js-text-to-download-input').val('');
      if ($('.modal-backdrop').length) {
        $('.modal-backdrop').remove();
      }
      phoneNoEnteredErrorTracking('');
    }
    if (clickedFrom == 'rdp-modal') {
      $('.send-text-btn-rdp-modal').find('span').removeClass('d-none');
      $('.send-text-btn-rdp-modal').find('img').addClass('d-none');
    } else {
      $('.send-text-btn').find('span').removeClass('d-none');
      $('.send-text-btn').find('img').addClass('d-none');
    }
  };
  branch.data(function(err,data){
    linkData = set_branch_link_data(linkData, customText, data)
    fbq('trackCustom', 'SMSOutboundClick');
    axios.get('api/businesses/log_numbers', { params: { number: phone } }).then(response => {})
    branch.sendSMS(phone, linkData, options, callback);
  });
}

function sendSMSCustom(phone, errorElement, clickedFrom) {
  var linkData = {
    tags: [],
    channel: 'Website',
    feature: 'TextMeTheApp',
    data: {
      $custom_sms_text: `Hi! Tap the link below to download Seated and submit the receipt for your meal. Your rewards are waiting for you! {{ link }}`,
      foo: 'bar'
    }
  };
  var options = {};
  var callback = function(err, result) {
    if (err) {
      errorElement.text('Invalid Mobile Number').removeClass('d-none');
      phoneNoEnteredTracking("upload_receipt", "homepage", 'Invalid Mobile Number');
    }
    else {
      $('.modal-backdrop').remove();

      if (clickedFrom == 'rdp') {
        $('#uploadReceiptRdpModal').modal('hide');
        $('#is_modal_visited').val(true);

        if($('#provider_type').val() == 'PHONE') {
          $('.js-message-sent').removeClass('d-none');
          setPhoneNumber();
          if ($(window).width() >= 992) {
            $('#phoneOrderModal').modal();
          }
          else {
            $('#phoneOrderModalMobile').modal();
          }
        }
        else {
          $('#messageSentRdpModal').modal();
        }
      }
      else {
        $('#uploadReceiptModal').modal('hide');
        $('#messageSentModal').modal();
      }
      errorElement.text('REQUIRED').addClass('d-none');
      $('.js-text-to-download-input').val('');
      $('.js-send-btn').addClass('opacity-20');
      phoneNoEnteredTracking("upload_receipt", "homepage", '');
    }

    $('.js-send-btn').find('span').removeClass('d-none');
    $('.js-send-btn').find('img').addClass('d-none');
  };
  branch.data(function(err,data){
    linkData = set_branch_link_data(linkData, null, data)
    fbq('trackCustom', 'SMSOutboundClick');
    branch.sendSMS(phone, linkData, options, callback);
  });
}

function set_branch_link_data(linkData, customText, data){
  if(data.referring_link !== null && data.referring_link !== ''){
    response = JSON.parse(data.data)
    linkData = {
      tags: [],
      channel: response["~channel"],
      feature: response["~feature"],
      campaign: response["~campaign"],
      referring_link: response["~referring_link"],
      data: {
        $custom_sms_text: customText,
        'foo': 'bar'
      }
    };
  }
  return linkData
}


$(document).on('turbolinks:load', function() {
  // TTD PAGE CUSTOMIZATION
  customizeTtdView();
  checkPromoValue();
  var heroHeight = $('.text-to-download-hero').outerHeight();
  var scrollJackDuration = 750;
  // if ($('.text-to-download-hero').length) {
  $('#downloadModal').on('shown.bs.modal', function() {
    $('#downloadModal .QRMOdal__Container__QRBOx').first().focus();
  });
  $('#uploadReceiptModal').on('shown.bs.modal', function() {
    $('#uploadReceiptModal .QRMOdal__Container__QRBOx').first().focus();
  });
  $('#uploadReceiptRdpModal').on('shown.bs.modal', function() {
    $('#uploadReceiptRdpModal .QRMOdal__Container__QRBOx').first().focus();
  });
  $('#downloadmodal').on('shown.bs.modal', function(e) {
    $('#downloadmodal .QRMOdal__Container__QRBOx').get(0).focus();
  });
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    var lastScrollTop = 0;
    if ($('#text-to-download-page').length) {
      $(window).scroll(function() {
        // get current scroll position
        var currentScrollTop = $(this).scrollTop();
        // header shadow removed for the duration of scrolljacking
        if (currentScrollTop >= heroHeight && currentScrollTop <= scrollJackDuration + heroHeight) {
          $('header.sticky-top').css('background', "#fffdf9").css('box-shadow', 'none');
        } else if (currentScrollTop == 0) {
          $('header').removeAttr('style');
        } else {
          $('header.sticky-top').css('background', "#FBF6F2").css('box-shadow', "0px 0px 20px 5px #00000019");
        }
        // add phone scaling animation
        if (currentScrollTop > 550) {
          $('.text-to-download-explore .phone-map').removeClass('initial-state').addClass('final-state');
        } else {
          $('.text-to-download-explore .phone-map').removeClass('final-state').addClass('initial-state');
        }
        // get direction
        var direction = lastScrollTop > currentScrollTop ? 'up' : (lastScrollTop < currentScrollTop ? 'down' : 'none');
        lastScrollTop = currentScrollTop;
        // add scroll-jacking animation
        if ($(window).width() <= 991) {
          return;
        }
        var breakpoint1 = heroHeight + (scrollJackDuration/3);
        var breakpoint2 = heroHeight + ((2*scrollJackDuration)/3);
        if (direction == 'down') {
          if (currentScrollTop > breakpoint2) {
            handleScrollJacking('eat', 'earn');
          } else if (currentScrollTop > breakpoint1) {
            handleScrollJacking('explore', 'eat');
          }
        } else if (direction == 'up') {
          if (currentScrollTop < breakpoint1) {
            handleScrollJacking('eat', 'explore');
          } else if (currentScrollTop < breakpoint2) {
            handleScrollJacking('earn', 'eat');
          }
        }
      });

      var controller = null;
      if ($(window).width() > 991) {
        controller = initializeScrollMagic(heroHeight, scrollJackDuration);
      }
      $(window).resize(function() {
        if ($(window).width() > 991) {
          heroHeight = $('.text-to-download-hero').outerHeight();
          if (!controller) {
            controller = initializeScrollMagic(heroHeight, scrollJackDuration);
          }
        } else {
          $('#pinned-element').removeAttr('style');
          if (controller) {
            controller = controller.destroy(true);
          }
        }
      });
    }

    $('.send-text-btn').click(function() {
      if ($(this).find('span').hasClass('d-none')) {
        return;
      }
      $(this).find('span').addClass('d-none');
      $(this).find('img').removeClass('d-none');
      var phone = $(this).parent().find('.js-text-to-download-input').val();
      var errorElement = $(this).closest('.row').find('.text-to-download-error');
      if (phone == "") {
        $(this).find('span').removeClass('d-none');
        $(this).find('img').addClass('d-none');
        errorElement.text('REQUIRED').removeClass('d-none');
        errorElement.parent().find('.number-invalid-icon').removeClass('d-none');
        phoneNoEnteredErrorTracking('Mobile Number Required');
      } else {
        downloadModalPhoneEnterAmplitudeEvent($(this));
        if($(this).attr('name') == 'text-to-download'){
          customText = 'Your bonus reward is waiting! Tap here to download Seated for an extra $'+PROMO_REWARD+' back on your first meal: {{ link }}'
          sendSMS(phone, customText, errorElement, null);
        }
        else{
          sendSMS(phone, null, errorElement, null);
        }
      }
    });

  $('.send-text-btn-rdp-modal').click(function() {
    var phone = $(this).siblings('.js-text-to-download-input').val();
    var errorElement = $(this).closest('.downloadmodal__inputContainer').find('.text-to-download-error');
    $(this).find('span').addClass('d-none');
    $(this).find('img').removeClass('d-none');
    if (phone == "") {
      $(this).find('span').removeClass('d-none');
      $(this).find('img').addClass('d-none');
      errorElement.text('REQUIRED').removeClass('d-none');
      phoneNoEnteredErrorTracking('Mobile Number Required');
    } else {
      downloadModalPhoneEnterAmplitudeEvent($(this));
      const businessName = $('#rdp-modal-restaurant-name').text()
      var dateString = $('#time_picker_rdp').val() || $('#time_picker').val()
      var reservationDate = new Date(dateString.split(' ')[0] + ' ' +  dateString.split(' ')[1])
      var reservationDateString = ''
      reservationDateString = new Date(reservationDate).toDateString().slice(4)
      reservationDateString = `${reservationDateString.slice(0,3)} ${reservationDateString.slice(4,6)}, ${reservationDateString.slice(7,11)}`
      const timeSlotText = $('#selected-time-slot').text().trim()
      if (timeSlotText == 'WALK IN' || timeSlotText == 'WALK IN NOW') {
        customText = `${String.fromCodePoint(0x1F44B)} Seated here! Tap here to download the app, and use the “${timeSlotText}” button when you arrive at ${businessName}: {{ link }}`
      } else {
        customText = `${String.fromCodePoint(0x1F44B)} Seated here! Tap here to download the app and complete your booking at ${businessName} for ${reservationDateString}: {{ link }}`
      }
      clickedFrom = 'rdp-modal'
      sendSMS(phone, customText, errorElement, clickedFrom);
    }
  });

  $('.js-send-btn').click(function() {
    if ($(this).hasClass('opacity-20') || $(this).find('span').hasClass('d-none')) {
      return;
    }
    $(this).find('span').addClass('d-none');
    $(this).find('img').removeClass('d-none');
    var phone = $(this).siblings('.js-number-field').find('.js-text-to-download-input').val();
    var errorElement = $(this).siblings('.js-error-field').find('.text-to-download-error');
    var clickedFrom = 'home';
    if ($(this).hasClass('js-send-btn-rdp'))
      clickedFrom = 'rdp';
    if (phone == "") {
      errorElement.text('REQUIRED').removeClass('d-none');
    } else {
      sendSMSCustom(phone, errorElement, clickedFrom);
    }
  });

  $('body').on('click', '.js-rdp-menu-url', function(){
    $('#is_modal_visited').val(true);
    $('.modal-backdrop').remove();
    $('#uploadReceiptRdpModal').modal('hide');

    if ($('#provider_type').val() == 'PHONE' ) {
      $('.js-message-sent').addClass('d-none');
      setPhoneNumber();
      if ($(window).width() >= 992) {
        $('#phoneOrderModal').modal();
      }
      else {
        $('#phoneOrderModalMobile').modal();
      }
    }
    else {
      window.open($('#menu_url').val(), '_blank');
    }
  });

  $('body').on('click', '.js-phone-menu-url', function(){
      window.open($('#menu_url').val(), '_blank');
  });

  $('body').on('click', '.js-phone-call', function(){
    var link = "tel:+1" + $('#phone_number').val();
    window.location.href = link;
  });

});

function initializeScrollMagic(offset, duration) {
  var controller = new ScrollMagic.Controller();

  // create a scene
  new ScrollMagic.Scene({
    duration: duration,
    offset: offset
  }).setPin('#pinned-element')
    .addTo(controller);
  return controller;
}

function handleScrollJacking(from, to) {
  if ($('.text-to-download-'+from).hasClass('d-lg-none')) {
    return;
  }
  var fromElement = $('.'+from+'-animation');
  var toElement = $('.'+to+'-animation');
  var toPhoneElement = $('.'+to+'-phone-animation');
  var fromPhoneElement = $('.'+from+'-phone-animation');

  $(fromElement).addClass('animated fadeOutUp ');
  $(fromPhoneElement).addClass('animated fadeOut ');
  $(fromElement).on('animationend', function() {
    $('.text-to-download-'+from).addClass('d-lg-none');
    $(fromElement).removeClass('animated fadeOutUp ');
    $(fromPhoneElement).removeClass('animated fadeOut ');
    $('.text-to-download-'+to).removeClass('d-lg-none');
    $(toElement).addClass('animated fadeInUp ');
    $(toPhoneElement).addClass('animated fadeIn ');
    $(toElement).on('animationend', function() {
      $(toElement).removeClass('animated fadeInUp ');
      $(toPhoneElement).removeClass('animated fadeIn ');
      $(toElement).off('animationend');
    });
    $(fromElement).off('animationend');
  });
}
function checkPromoValue() {
  try {
    branch.data(function(err,data){
      if(data != null && data.referring_link !== null && data.referring_link !== ''){
        response = JSON.parse(data.data)
        referring_link = response["~referring_link"]
        channel = response["~channel"]
        axios.get('api/businesses/check_promo_code', { params: { referring_link: referring_link, channel: channel } }).then(response => {
          if(response.data.data.promoCodeValid != undefined){
            amount = response.data.data.promoCodeAmountInDollar
            $(".promo-amount").html(amount);
            PROMO_REWARD = amount
          }
          showPromoValue()
        }).catch(function (error) {
          showPromoValue()
        })
      }else{
        showPromoValue()
      }
    });
  }
  catch(err) {
    showPromoValue()
  }
}
function showPromoValue(){
  $('.promo-value-div').attr("style", "opacity: 1");
}

function customizeTtdView() {
  branch.init( BRANCH_KEY , function(err,data) {
    custom_logo   = data.data_parsed['ttd_custom_logo']
    custom_header = data.data_parsed['ttd_header']
    custom_image1 = data.data_parsed['ttd_image1']
    custom_image2 = data.data_parsed['ttd_image2']
    custom_qr_img = data.data_parsed['ttd_custom_qr']
    parent_wrapper = $(".text-to-download-page-wrap");

    if(custom_logo != undefined && custom_logo != "") {
      $(parent_wrapper).find(".QRLP__logoContainer img").attr('src', custom_logo);
      $(parent_wrapper).find(".QRLP__logoContainer img").css( "max-height", "35px" );
    }
    $(parent_wrapper).find(".QRLP__logoContainer img").removeClass('d-none');

    if(custom_qr_img != undefined && custom_qr_img != "") {
      $(parent_wrapper).find(".QR__lp__QRBox img").attr('src', custom_qr_img);
      $(parent_wrapper).find(".QRLP__downloadAPPSection__QRBox__QRImg img").attr('src', custom_qr_img);
    }
    $(parent_wrapper).find(".QR__lp__QRBox img").removeClass('d-none');
    $(parent_wrapper).find(".QRLP__downloadAPPSection__QRBox__QRImg img").removeClass('d-none');

    if(custom_image1 != undefined && custom_image1 != "") {
      $(parent_wrapper).find(".QRLP__leftImage__fg").attr('src', custom_image1);
      // $(parent_wrapper).find(".cover-area-content .cover-img-mobile").attr('src', custom_image1);
    }
    $(parent_wrapper).find(".QRLP__leftImage__fg").removeClass('d-none');
    // $(parent_wrapper).find(".cover-area-content .cover-img-mobile").removeClass('d-none');

    if(custom_image2 != undefined && custom_image2 != "") {
      $(parent_wrapper).find(".QRLP__rightImage__fg").attr('src', custom_image2);
    }
    $(parent_wrapper).find(".QRLP__rightImage__fg").removeClass('d-none');

    if(custom_header != undefined && custom_header != "") {
      $(parent_wrapper).find(".QRLP__headingContainer h1").html(custom_header);
    }
    $(parent_wrapper).find(".QRLP__headingContainer h1").removeClass('d-none');

    $('.m_custom_spinner').addClass('d-none');
  });
}
