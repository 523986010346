$(document).on('turbolinks:load', function () {
  // $('.my-select').selectpicker();
  $('[id^=contact_]').on('input', function () {
    var input = $(this);
    var data = input.val();
    var id = input.attr("id");
    if (data) {
      $('#' + id + '_error').addClass("d-none");
    }
    else {
      $('#' + id + '_error').removeClass("d-none");
    }
  });

  $("[id^=event_]").on('input', function () {
    var input = $(this);
    var data = input.val();
    var id = input.attr("id");
    if (data) {
      $('#' + id + '_error').addClass("d-none");
    }
    else {
      $('#' + id + '_error').removeClass("d-none");
    }
  });


  $("#contact_submit").click(function (e) {
    var requiredFields = ["#contact_name", "#contact_email", "#contact_restaurant_name", "#contact_phone"];
    var error_free = true;
    $.each(requiredFields, function(index, item) {
        var element = $(item);
        if (!element.val()) {
          var element_id = "#" + element.attr('id') + "_error";
          $(element_id).removeClass("d-none");
          error_free = false;
        }
    });
    
    if (!error_free) {
      event.preventDefault();
    }
  });

  $(".toast_form_submit").click(function (e) {
    var form_id = $(this).attr("data-id");
    var requiredFields = ["#contact_name_"+ form_id, "#contact_email_"+ form_id, "#contact_restaurant_name_"+ form_id, "#contact_phone_"+ form_id];
    var error_free = true;
    $.each(requiredFields, function(index, item) {
      var element = $(item);
      if (!element.val()) {
        var element_id = "#" + element.attr('id') + "_error";
        $(element_id).removeClass("d-none");
        error_free = false;
      }
    });

    if (!error_free) {
      event.preventDefault();
    }
  });

  $("#relief_submit").click(function (e) {
    e.preventDefault();
    var requiredFields = ["#contact_name", "#contact_phone", "#contact_restaurant"];
    var error_free = true;
    $.each(requiredFields, function(index, item) {
      var element = $(item);
      if (!element.val()) {
        var element_id = "#" + element.attr('id') + "_error";
        $(element_id).removeClass("d-none");
        error_free = false;
      }
    });
    if (error_free) {
      var code = $('#code').text();
      if (code != "") {
        Cookies.set('code', code);
      }
      setRetUrl();
      var amountValue= $('#amount').val();
      $('#amount').val(parseInt(amountValue));
      $('#relief-form').submit();
    }
  });

  $("#event_submit").click(function (e) {
    var requiredFields = ["#event_name", "#event_email", "#event_date", "#event_headcount", "#event_description", "#event_city", "#event_type"];
    var requiredCheckboxFields = ["#event_account_requirement", "#event_financial_requirement"];
    var error_free = true;
    $.each(requiredFields, function(index, item) {
        var element = $(item);
        if (!element.val()) {
          var element_id = "#" + element.attr('id') + "_error";
          $(element_id).removeClass("d-none");
          error_free = false;
        }
    });
    $.each(requiredCheckboxFields, function(index, item) {
      var element = $(item);
      if (!element.is(':checked')) {
        var element_id = "#" + element.attr('id') + "_error";
        $(element_id).removeClass("d-none");
        error_free = false;
      }
    });

    if (!error_free) {
      event.preventDefault();
    } else {
      $(this).addClass('disabled');
      var city_value = $('#event_city').val();
      var neighborhood_value = $('#event_neighborhood').val();
      $('#event_neighborhood').val(neighborhood_value ? city_value + ' - ' + neighborhood_value : city_value)
    };
  });
  
  $(document).click(function(event) {
    var clickover = $(event.target);
    if (clickover.is('#city_link') || clickover.parent().is('#city_link')) {
      $('.city-dropdown-menu').show();
    } else {
      $('.city-dropdown-menu').hide();
    }
  });

  $(document).click(function(event) {
    var clickover = $(event.target);
    if (clickover.is('#event_type_link') || clickover.parent().is('#event_type_link')) {
      $('.event-type-dropdown-menu').show();
    } else {
      $('.event-type-dropdown-menu').hide();
    }
  });
  
  $('.city-dropdown-menu a').click(function(e) {
    e.preventDefault();
    var city_id = '#event_city';
    $(city_id).val($(this).text());
    $('#city_value').val($(this).data('value'));
    if ($(city_id).val() != "") {
      $(city_id + '_error').addClass("d-none");
    }
  });

  $('.event-type-dropdown-menu a').click(function(e) {
    e.preventDefault();
    var event_type = '#event_type';
    $(event_type).val($(this).text());
    $('#event_type_value').val($(this).data('value'));
    if ($(event_type).val() != "") {
      $(event_type + '_error').addClass("d-none");
    }
  });
  
});

function setRetUrl(){
  var amount = $('#amount').data('amount');
  var city = $('#city').data('city');
  //Todo: get value from drop down
  var restaurant = 'general';
  if ($('.relief-search-box li :checked').length > 0) {
    restaurant = $('.relief-search-box li :checked').first().data('name');
  }
  var retBaseUrl = $('#retURL').val();
  $('#retURL').val(retBaseUrl+"?city="+city+"&amount="+amount+"&restaurant="+restaurant);

}
